import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { CarPickerComponent } from './car-picker/car-picker.component';
import { CarFilterComponent } from './car-filter/car-filter.component';
import { CarFilterLgvComponent } from './car-filter-lgv/car-filter-lgv.component';
import { CarFilterEvComponent } from './car-filter-ev/car-filter-ev.component';
import { LeadFormComponent } from './lead-form/lead-form.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/osebna-vozila',
    pathMatch: 'full'
  },
  {
    path: 'lgv',
    redirectTo: '/lahka-gospodarska-vozila?utm_source=selfmailer-voucher&utm_medium=mailing&utm_campaign=LCV-R-B2C_SI-112018',
    pathMatch: 'full'
  },
  {
    path: 'novi-twingo',
    redirectTo: '/osebna-vozila/twingo',
    pathMatch: 'full'
  },
  {
    path: 'novi-kadjar',
    redirectTo: '/osebna-vozila/kadjar',
    pathMatch: 'full'
  },
  {
    path: 'dealer/:bir',
    redirectTo: '/dealer/:bir/osebna-vozila',
    pathMatch: 'full'
  },
  {
    path: ':tab',
    component: CarPickerComponent,
    children: []
  },
  {
    path: 'dealer/:bir/:tab',
    component: CarPickerComponent,
    children: []
  },
  {
    path: 'osebna-vozila/novi-megane-grandcoupe',
    redirectTo: '/osebna-vozila/megane-grandcoupe',
  },
  {
    path: 'osebna-vozila/novi-scenic',
    redirectTo: '/osebna-vozila/scenic',
  },
  // {
  //   path: 'osebna-vozila/novi-captur',
  //   redirectTo: '/osebna-vozila/captur',
  // },
  {
    path: 'osebna-vozila/novi-koleos',
    redirectTo: '/osebna-vozila/koleos',
  },
  {
    path: 'osebna-vozila/novi-grand-scenic',
    redirectTo: '/osebna-vozila/grand-scenic',
  },
  {
    path: 'elektricna-vozila/novi-kangoo-ze',
    redirectTo: '/elektricna-vozila/kangoo-ze',
  },
  {
    path: 'osebna-vozila/:model',
    component: CarFilterComponent,
    children: []
  },
  {
    path: 'dealer/:bir/osebna-vozila/:model',
    component: CarFilterComponent,
    children: []
  },
  {
    path: 'osebna-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: 'dealer/:bir/osebna-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: 'lahka-gospodarska-vozila/:model',
    component: CarFilterLgvComponent,
    children: []
  },
  {
    path: 'dealer/:bir/lahka-gospodarska-vozila/:model',
    component: CarFilterLgvComponent,
    children: []
  },
  {
    path: 'lahka-gospodarska-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: 'dealer/:bir/lahka-gospodarska-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: 'elektricna-vozila/kangoo-ze',
    component: CarFilterLgvComponent,
    children: []
  },
  {
    path: 'dealer/:bir/elektricna-vozila/kangoo-ze',
    component: CarFilterLgvComponent,
    children: []
  },
  {
    path: 'elektricna-vozila/:model',
    component: CarFilterEvComponent,
    children: []
  },
  {
    path: 'dealer/:bir/elektricna-vozila/:model',
    component: CarFilterEvComponent,
    children: []
  },
  {
    path: 'elektricna-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: 'dealer/:bir/elektricna-vozila/:model/:vin',
    component: LeadFormComponent,
    children: []
  },
  {
    path: '*',
    redirectTo: '/osebna-vozila',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
