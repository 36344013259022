import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { Car } from './car';
import { CarListService } from './car-list.service';
import { CarPickerService } from './car-picker.service';

@Injectable()
export class FilterListService {
  model: Car;
  list: any[];
  filter: any;

  constructor(
    private carListService: CarListService,
    private carPickerService: CarPickerService
  ) { }

     

  getAll(pageid: string, bir = ''): Observable<any> {
    this.model = this.carPickerService.getModel(pageid);
    if(!this.model){
      return this.carListService.getByModel('AAA', bir);
    }
    return this.carListService.getByModel(<string>this.model.modelCode, bir);

  }

  getFilters(pageid: string, bir = ''): any {
    this.model = this.carPickerService.getModel(pageid);
    if(!this.model){
      return this.carListService.getByModel('AAA', bir);
    }
    return this.carListService.getByModel(<string>this.model.modelCode, bir);
  }

}