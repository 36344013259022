import { Injectable } from '@angular/core';

import { Dealer } from './dealer';

@Injectable()
export class DealerListService {

  dealers: Dealer[] = [
    {
      "id": "si_renault_70500094-001",
      "dealerId": "70500094",
      "tradingName": "013 d.o.o.",
      "geolocation": {
        "latitude": 46.003849999,
        "longitude": 14.02994
      },
      "suggestedName": "013 d.o.o. - Idrija",
      "city": "Idrija",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500105-001",
      "dealerId": "70500105",
      "tradingName": "AH TERBUC d.o.o.",
      "geolocation": {
        "latitude": 46.434920247,
        "longitude": 15.94820048
      },
      "suggestedName": "AH TERBUC d.o.o. - Dornava",
      "city": "Dornava",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500089-001",
      "dealerId": "70500089",
      "tradingName": "AHPA,trgovina in storitve,d.o.o.",
      "geolocation": {
        "latitude": 46.4172592,
        "longitude": 15.881620399
      },
      "suggestedName": "AHPA,trgovina in storitve,d.o.o. - Ptuj",
      "city": "Ptuj",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500079-001",
      "dealerId": "70500079",
      "tradingName": "ASP d.o.o.",
      "geolocation": {
        "latitude": 46.426949055,
        "longitude": 14.081769816
      },
      "suggestedName": "ASP d.o.o. - Jesenice",
      "city": "Jesenice",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500015-001",
      "dealerId": "70500015",
      "tradingName": "ASP d.o.o. PE Lesce",
      "geolocation": {
        "latitude": 46.36549,
        "longitude": 14.161919599
      },
      "suggestedName": "ASP d.o.o. PE Lesce - Lesce",
      "city": "Lesce",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500063-001",
      "dealerId": "70500063",
      "tradingName": "AVTO CENTER VOVK d.o.o.",
      "geolocation": {
        "latitude": 45.907568359,
        "longitude": 15.02245992
      },
      "suggestedName": "AVTO CENTER VOVK d.o.o. - Trebnje",
      "city": "Trebnje",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500891-001",
      "dealerId": "70500891",
      "tradingName": "AVTO FRELIH, ŽIRI, d.o.o.",
      "geolocation": {
        "latitude": 46.04892985,
        "longitude": 14.111229451
      },
      "suggestedName": "AVTO FRELIH, ŽIRI, d.o.o. - Žiri",
      "city": "Žiri",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500058-001",
      "dealerId": "70500058",
      "tradingName": "AVTO KOLETNIK d.o.o.",
      "geolocation": {
        "latitude": 46.558911132,
        "longitude": 15.664009602
      },
      "suggestedName": "AVTO KOLETNIK d.o.o. - Maribor",
      "city": "Maribor",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500053-001",
      "dealerId": "70500053",
      "tradingName": "AVTO KOLETNIK d.o.o., PSC Radlje",
      "geolocation": {
        "latitude": 46.611409505,
        "longitude": 15.225089518
      },
      "suggestedName": "AVTO KOLETNIK d.o.o., PSC Radlje - Radlje ob Dravi",
      "city": "Radlje ob Dravi",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500033-001",
      "dealerId": "70500033",
      "tradingName": "AVTO SERVIS STRAŠEK d.o.o.",
      "geolocation": {
        "latitude": 46.2514496,
        "longitude": 15.5710697
      },
      "suggestedName": "AVTO SERVIS STRAŠEK d.o.o. - Podplat",
      "city": "Podplat",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500073-001",
      "dealerId": "70500073",
      "tradingName": "AVTO SET d.o.o.",
      "geolocation": {
        "latitude": 46.110388183,
        "longitude": 14.593400065
      },
      "suggestedName": "AVTO SET d.o.o. - Domžale",
      "city": "Domžale",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500351-001",
      "dealerId": "70500351",
      "tradingName": "AVTO ŠIVIC servis in trgovina d.o.o.",
      "geolocation": {
        "latitude": 46.345019531,
        "longitude": 14.29053955
      },
      "suggestedName": "AVTO ŠIVIC servis in trgovina d.o.o. - Tržič",
      "city": "Tržič",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500045-001",
      "dealerId": "70500045",
      "tradingName": "AVTO ŠKAFAR d.o.o.",
      "geolocation": {
        "latitude": 46.65895996,
        "longitude": 16.174360148
      },
      "suggestedName": "AVTO ŠKAFAR d.o.o. - Murska Sobota",
      "city": "Murska Sobota",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500109-001",
      "dealerId": "70500109",
      "tradingName": "AVTO ŠKAFAR d.o.o. PE Lendava",
      "geolocation": {
        "latitude": 46.551619466,
        "longitude": 16.447549438
      },
      "suggestedName": "AVTO ŠKAFAR d.o.o. PE Lendava - Lendava",
      "city": "Lendava",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500113-001",
      "dealerId": "70500113",
      "tradingName": "AVTOCENTER BRDAR d.o.o.",
      "geolocation": {
        "latitude": 45.750992838,
        "longitude": 15.146260579
      },
      "suggestedName": "AVTOCENTER BRDAR d.o.o. - Novo mesto",
      "city": "Novo mesto",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500041-001",
      "dealerId": "70500041",
      "tradingName": "AVTOCENTER KRAŠNA d.o.o.PE Boštanj",
      "geolocation": {
        "latitude": 46.0103188,
        "longitude": 15.291279799
      },
      "suggestedName": "AVTOCENTER KRAŠNA d.o.o.PE Boštanj - Boštanj",
      "city": "Boštanj",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500031-001",
      "dealerId": "70500031",
      "tradingName": "AVTOCENTER KRAŠNA d.o.o.Škocjan",
      "geolocation": {
        "latitude": 45.9105343,
        "longitude": 15.290452954
      },
      "suggestedName": "AVTOCENTER KRAŠNA d.o.o.Škocjan - Škocjan",
      "city": "Škocjan",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500086-001",
      "dealerId": "70500086",
      "tradingName": "AVTOCENTER NOVAK d.o.o.",
      "geolocation": {
        "latitude": 45.70765,
        "longitude": 13.86228
      },
      "suggestedName": "AVTOCENTER NOVAK d.o.o. - Sežana",
      "city": "Sežana",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500036-001",
      "dealerId": "70500036",
      "tradingName": "AVTOCENTER ORMOŽ d.o.o.",
      "geolocation": {
        "latitude": 46.411915,
        "longitude": 16.158566666
      },
      "suggestedName": "AVTOCENTER ORMOŽ d.o.o. - ORMOŽ",
      "city": "Ormož",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500050-001",
      "dealerId": "70500050",
      "tradingName": "AVTOHIŠA MALGAJ d.o.o. PSC Ljubljana",
      "geolocation": {
        "latitude": 46.040833333,
        "longitude": 14.473333333
      },
      "suggestedName": "AVTOHIŠA MALGAJ d.o.o. PSC Ljubljana - Ljubljana",
      "city": "Ljubljana",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500007-001",
      "dealerId": "70500007",
      "tradingName": "AVTOHIŠA MALGAJ,d.o.o.",
      "geolocation": {
        "latitude": 46.172989908,
        "longitude": 15.049420166
      },
      "suggestedName": "AVTOHIŠA MALGAJ,d.o.o. - Trbovlje",
      "city": "Trbovlje",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500108-001",
      "dealerId": "70500108",
      "tradingName": "AVTOHIŠA REAL,d.o.o.",
      "geolocation": {
        "latitude": 46.074780273,
        "longitude": 14.504159545
      },
      "suggestedName": "AVTOHIŠA REAL,d.o.o. - Ljubljana",
      "city": "Ljubljana",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500035-001",
      "dealerId": "70500035",
      "tradingName": "AVTOHIŠA REAL, d.o.o. PE Remont Kranj",
      "geolocation": {
        "latitude": 46.228233333,
        "longitude": 14.3503
      },
      "suggestedName": "AVTOHIŠA REAL, d.o.o. PE Remont Kranj - Kranj",
      "city": "Kranj",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500034-001",
      "dealerId": "70500034",
      "tradingName": "AVTOHIŠA VRTIN d.o.o.",
      "geolocation": {
        "latitude": 45.565262858,
        "longitude": 15.174120076
      },
      "suggestedName": "AVTOHIŠA VRTIN d.o.o. - Črnomelj",
      "city": "Črnomelj",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500100-001",
      "dealerId": "70500100",
      "tradingName": "AVTOSERVIS BLATNIK d.o.o.",
      "geolocation": {
        "latitude": 45.936218261,
        "longitude": 14.803749593
      },
      "suggestedName": "AVTOSERVIS BLATNIK d.o.o. - Ivančna Gorica",
      "city": "Ivančna Gorica",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500056-001",
      "dealerId": "70500056",
      "tradingName": "AVTOSERVIS KALAN d.o.o.",
      "geolocation": {
        "latitude": 46.0645599,
        "longitude": 14.499340099
      },
      "suggestedName": "AVTOSERVIS KALAN d.o.o. - Ljubljana",
      "city": "Ljubljana",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500084-001",
      "dealerId": "70500084",
      "tradingName": "AVTOSERVIS VIDRIH d.o.o.",
      "geolocation": {
        "latitude": 45.790987141,
        "longitude": 14.367019653
      },
      "suggestedName": "AVTOSERVIS VIDRIH d.o.o. - Cerknica",
      "city": "Cerknica",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500003-001",
      "dealerId": "70500003",
      "tradingName": "AVTOTEHNIKA Celje d.o.o.",
      "geolocation": {
        "latitude": 46.24001,
        "longitude": 15.28085
      },
      "suggestedName": "AVTOTEHNIKA Celje d.o.o. - CELJE",
      "city": "Celje",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500096-001",
      "dealerId": "70500096",
      "tradingName": "AVTOVAL d.o.o.",
      "geolocation": {
        "latitude": 45.96672,
        "longitude": 14.66222
      },
      "suggestedName": "AVTOVAL d.o.o. - Grosuplje",
      "city": "Grosuplje",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500099-001",
      "dealerId": "70500099",
      "tradingName": "CAST d.o.o PE Slovenj Gradec",
      "geolocation": {
        "latitude": 46.532676134,
        "longitude": 15.072641064
      },
      "suggestedName": "CAST d.o.o - PE Slovenj Gradec - SLOVENJ GRADEC",
      "city": "Slovenj Gradec",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500068-001",
      "dealerId": "70500068",
      "tradingName": "FPL AVTOCENTER PUŠNIK d.o.o.",
      "geolocation": {
        "latitude": 46.387239583,
        "longitude": 15.57073059
      },
      "suggestedName": "FPL AVTOCENTER PUŠNIK d.o.o. - Slovenska Bistrica",
      "city": "Slovenska Bistrica",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500069-001",
      "dealerId": "70500069",
      "tradingName": "PLEŠKO CARS, d.o.o. Brezovica",
      "geolocation": {
        "latitude": 46.026151529,
        "longitude": 14.415379842
      },
      "suggestedName": "PLEŠKO CARS, d.o.o. Brezovica - Brezovica pri Ljubljani",
      "city": "Brezovica pri Ljubljani",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500095-001",
      "dealerId": "70500095",
      "tradingName": "POMURSKI AVTOCENTER d.o.o.",
      "geolocation": {
        "latitude": 46.649189,
        "longitude": 16.1497898
      },
      "suggestedName": "POMURSKI AVTOCENTER d.o.o. - Murska Sobota",
      "city": "Murska Sobota",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500070-001",
      "dealerId": "70500070",
      "tradingName": "PREŠA, d.o.o.",
      "geolocation": {
        "latitude": 46.2505417,
        "longitude": 14.486062
      },
      "suggestedName": "PREŠA, d.o.o., Cerklje - Cerklje na Gorenjskem",
      "city": "Cerklje na Gorenjskem",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500754-001",
      "dealerId": "70500754",
      "tradingName": "PSC LOGATEC d.o.o.",
      "geolocation": {
        "latitude": 45.912609863,
        "longitude": 14.236909993
      },
      "suggestedName": "PSC LOGATEC d.o.o. - Logatec",
      "city": "Logatec",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500106-001",
      "dealerId": "70500106",
      "tradingName": "PSC Ljutomer d.o.o.",
      "geolocation": {
        "latitude": 46.5307,
        "longitude": 16.1856699
      },
      "suggestedName": "PSC Ljutomer d.o.o. - Ljutomer",
      "city": "Ljutomer",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500097-001",
      "dealerId": "70500097",
      "tradingName": "SR Levec d.o.o",
      "geolocation": {
        "latitude": 46.2407899,
        "longitude": 15.21917
      },
      "suggestedName": "SR Levec d.o.o - Petrovče",
      "city": "Petrovče",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500850-001",
      "dealerId": "70500850",
      "tradingName": "TIMI, d.o.o., NOVO MESTO",
      "geolocation": {
        "latitude": 45.8070501,
        "longitude": 15.1961444
      },
      "suggestedName": "TIMI, d.o.o., NOVO MESTO - Novo mesto",
      "city": "Novo mesto",
      "markerStyle": "beta",
      "dealerType": "R2"
    },
    {
      "id": "si_renault_70500011-001",
      "dealerId": "70500011",
      "tradingName": "TPV AVTO d.o.o PE Brežice",
      "geolocation": {
        "latitude": 45.923254394,
        "longitude": 15.588982137
      },
      "suggestedName": "TPV AVTO d.o.o PE Brežice - Brežice",
      "city": "Brežice",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500060-001",
      "dealerId": "70500060",
      "tradingName": "TPV AVTO d.o.o.",
      "geolocation": {
        "latitude": 45.799080403,
        "longitude": 15.177409871
      },
      "suggestedName": "TPV AVTO d.o.o. - Novo mesto",
      "city": "Novo mesto",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500004-001",
      "dealerId": "70500004",
      "tradingName": "Trgo ABC d.o.o. - Koper",
      "geolocation": {
        "latitude": 45.538928222,
        "longitude": 13.730490112
      },
      "suggestedName": "Trgo ABC d.o.o. - Koper",
      "city": "Koper",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500913-001",
      "dealerId": "70500913",
      "tradingName": "Trgo ABC d.o.o. PE Ajdovščina",
      "geolocation": {
        "latitude": 45.886909993,
        "longitude": 13.898240152
      },
      "suggestedName": "Trgo ABC d.o.o. PE Ajdovščina - Ajdovščina",
      "city": "Ajdovščina",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500032-001",
      "dealerId": "70500032",
      "tradingName": "Trgo ABC d.o.o. PE Nova Gorica",
      "geolocation": {
        "latitude": 45.956310299,
        "longitude": 13.6617203
      },
      "suggestedName": "Trgo ABC d.o.o. PE Nova Gorica - Nova Gorica",
      "city": "Nova Gorica",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500078-001",
      "dealerId": "70500078",
      "tradingName": "Trgo ABC d.o.o. PE Postojna",
      "geolocation": {
        "latitude": 45.772269694,
        "longitude": 14.199500528
      },
      "suggestedName": "Trgo ABC d.o.o. PE Postojna - POSTOJNA",
      "city": "Postojna",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500059-001",
      "dealerId": "70500059",
      "tradingName": "Trgo ABC d.o.o. PE Tolmin",
      "geolocation": {
        "latitude": 46.180537923,
        "longitude": 13.744850667
      },
      "suggestedName": "Trgo ABC d.o.o. PE Tolmin - TOLMIN",
      "city": "Tolmin",
      "markerStyle": "alpha",
      "dealerType": "R1"
    },
    {
      "id": "si_renault_70500009-001",
      "dealerId": "70500009",
      "tradingName": "ŠTAJERSKI AVTO DOM d.o.o.",
      "geolocation": {
        "latitude": 46.536283365,
        "longitude": 15.646189371
      },
      "suggestedName": "ŠTAJERSKI AVTO DOM d.o.o. - Maribor",
      "city": "Maribor",
      "markerStyle": "alpha",
      "dealerType": "R1"
    }
  ]

  constructor() { }

  getAll(): Dealer[] {
    return this.dealers
  }

  find(bir: string): Dealer {
    return this.dealers.find( dealer => dealer.dealerId == bir);
  }

}
