import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { TranslationsService } from './local/translations.service';

@Injectable()
export class CarListService {

  constructor(
    private http: HttpClient,
    public trns: TranslationsService
  ) {}

  getModels(bir = ''): any {
    if(bir == '') {
      return this.http.jsonp(this.trns.translation.general.databaseUrl, 'callback');
    } else {
      return this.http.jsonp("/api/dealer/models/" + bir + "/renault/sl", 'callback');
    }
  }

  getByModel(modelCode: string = '', bir = ''): any {
    if(bir == '') {
      return this.http.jsonp(this.trns.translation.general.databaseVehiclesUrl + modelCode, 'callback');
    } else {
      return this.http.jsonp("/api/dealer/vehicles/" + bir + "/renault/sl/" + modelCode, 'callback');
    }
  }

  getByVin(vin: string): any {
    return this.http.jsonp(this.trns.translation.general.databaseVehicleUrl + vin, 'callback');
  }
}
