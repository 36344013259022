import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientJsonpModule } from '@angular/common/http';

import { MyDatePickerModule } from 'mydatepicker';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { Angulartics2Module, Angulartics2GoogleAnalytics } from 'angulartics2';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarFilterComponent } from './car-filter/car-filter.component';
import { CarPickerComponent } from './car-picker/car-picker.component';
import { LeadFormComponent } from './lead-form/lead-form.component';
import { TabComponent } from './car-picker/tab/tab.component';
import { CarFilterLgvComponent } from './car-filter-lgv/car-filter-lgv.component';
import { CarFilterEvComponent } from './car-filter-ev/car-filter-ev.component';

@NgModule({
  declarations: [
    AppComponent,
    CarFilterComponent,
    CarPickerComponent,
    LeadFormComponent,
    TabComponent,
    CarFilterLgvComponent,
    CarFilterEvComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MyDatePickerModule,
    NguiAutoCompleteModule,
    Angulartics2Module.forRoot([ Angulartics2GoogleAnalytics ])
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
