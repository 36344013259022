import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CarPickerService } from '../../car-picker.service';
import { Tab } from '../../tab';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {

  tabs: Tab[];
  currentTab: string;
  cTab: Tab;

  constructor(
    private carDataService: CarPickerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.tabs = this.carDataService.getTabs();
    this.route.params.subscribe(params => {
      this.currentTab = params['tab'] || 'osebna-vozila';
      this.cTab = this.tabs
        .filter(tab => tab.id === this.currentTab)
        .pop();
      console.log(this.cTab);
    });
  }

}
