import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  constructor(
    private router: Router,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) { }
  
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      var scrollStep = -window.pageYOffset / (300/15);
      var scrollInterval = setInterval(function(){
        if( window.pageYOffset != 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval)
        }
      }, 15);
    });
  }
}
