import { Injectable } from '@angular/core';

import { City } from './city';

@Injectable()
export class CitySearchService {

  cities: City[] = [
    {
      "zip": 8341,
      "city": "Adlešiči",
      "latitude": 45.52219,
      "longitude": 15.30648
    },
    {
      "zip": 5270,
      "city": "Ajdovščina",
      "latitude": 45.88601,
      "longitude": 13.90946
    },
    {
      "zip": 6280,
      "city": "Ankaran",
      "latitude": 45.58333,
      "longitude": 13.78333
    },
    {
      "zip": 9253,
      "city": "Apače",
      "latitude": 46.69722,
      "longitude": 15.91056
    },
    {
      "zip": 8253,
      "city": "Artiče",
      "latitude": 45.95389,
      "longitude": 15.58778
    },
    {
      "zip": 4275,
      "city": "Begunje na Gorenjskem",
      "latitude": 46.38333,
      "longitude": 14.21667
    },
    {
      "zip": 1382,
      "city": "Begunje pri Cerknici",
      "latitude": 45.81806,
      "longitude": 14.38222
    },
    {
      "zip": 9231,
      "city": "Beltinci",
      "latitude": 46.60528,
      "longitude": 16.24056
    },
    {
      "zip": 2234,
      "city": "Benedikt",
      "latitude": 46.60861,
      "longitude": 15.88833
    },
    {
      "zip": 2345,
      "city": "Bistrica ob Dravi",
      "latitude": 46.55764,
      "longitude": 15.55426
    },
    {
      "zip": 3256,
      "city": "Bistrica ob Sotli",
      "latitude": 46.05889,
      "longitude": 15.66417
    },
    {
      "zip": 8259,
      "city": "Bizeljsko",
      "latitude": 46.03333,
      "longitude": 15.66667
    },
    {
      "zip": 1223,
      "city": "Blagovica",
      "latitude": 46.17694,
      "longitude": 14.80722
    },
    {
      "zip": 8283,
      "city": "Blanca",
      "latitude": 45.98333,
      "longitude": 15.4
    },
    {
      "zip": 4260,
      "city": "Bled",
      "latitude": 46.36917,
      "longitude": 14.11361
    },
    {
      "zip": 4273,
      "city": "Blejska Dobrava",
      "latitude": 46.4,
      "longitude": 14.1
    },
    {
      "zip": 9265,
      "city": "Bodonci",
      "latitude": 46.74417,
      "longitude": 16.09222
    },
    {
      "zip": 9222,
      "city": "Bogojina",
      "latitude": 46.67611,
      "longitude": 16.28389
    },
    {
      "zip": 4263,
      "city": "Bohinjska Bela",
      "latitude": 46.34889,
      "longitude": 14.06389
    },
    {
      "zip": 4264,
      "city": "Bohinjska Bistrica",
      "latitude": 46.27216,
      "longitude": 13.9535
    },
    {
      "zip": 4265,
      "city": "Bohinjsko jezero",
      "latitude": 46.28556,
      "longitude": 13.86194
    },
    {
      "zip": 1353,
      "city": "Borovnica",
      "latitude": 45.91583,
      "longitude": 14.36306
    },
    {
      "zip": 8294,
      "city": "Boštanj",
      "latitude": 46.01444,
      "longitude": 15.2825
    },
    {
      "zip": 5230,
      "city": "Bovec",
      "latitude": 46.33808,
      "longitude": 13.55245
    },
    {
      "zip": 5295,
      "city": "Branik",
      "latitude": 45.85722,
      "longitude": 13.78417
    },
    {
      "zip": 3314,
      "city": "Braslovče",
      "latitude": 46.28972,
      "longitude": 15.03889
    },
    {
      "zip": 5223,
      "city": "Breginj",
      "latitude": 46.26472,
      "longitude": 13.42778
    },
    {
      "zip": 8280,
      "city": "Brestanica",
      "latitude": 45.99529,
      "longitude": 15.4775
    },
    {
      "zip": 2354,
      "city": "Bresternica",
      "latitude": 46.56972,
      "longitude": 15.575
    },
    {
      "zip": 4243,
      "city": "Brezje",
      "latitude": 46.35,
      "longitude": 14.96667
    },
    {
      "zip": 1351,
      "city": "Brezovica pri Ljubljani",
      "latitude": 46.03333,
      "longitude": 14.4
    },
    {
      "zip": 8250,
      "city": "Brežice",
      "latitude": 45.90333,
      "longitude": 15.59111
    },
    {
      "zip": 8321,
      "city": "Brusnice",
      "latitude": 45.81667,
      "longitude": 15.25
    },
    {
      "zip": 3255,
      "city": "Buče",
      "latitude": 46.10795,
      "longitude": 15.57009
    },
    {
      "zip": 8276,
      "city": "Bučka",
      "latitude": 45.93333,
      "longitude": 15.31667
    },
    {
      "zip": 9261,
      "city": "Cankova",
      "latitude": 46.72083,
      "longitude": 16.0225
    },
    {
      "zip": 3000,
      "city": "Celje",
      "latitude": 46.25,
      "longitude": 15.27028
    },
    {
      "zip": 4207,
      "city": "Cerklje na Gorenjskem",
      "latitude": 46.25417,
      "longitude": 14.48861
    },
    {
      "zip": 8263,
      "city": "Cerklje ob Krki",
      "latitude": 45.88343,
      "longitude": 15.5128
    },
    {
      "zip": 1380,
      "city": "Cerknica",
      "latitude": 45.8,
      "longitude": 14.36667
    },
    {
      "zip": 5282,
      "city": "Cerkno",
      "latitude": 46.12556,
      "longitude": 13.98167
    },
    {
      "zip": 2236,
      "city": "Cerkvenjak",
      "latitude": 46.57056,
      "longitude": 15.94361
    },
    {
      "zip": 2215,
      "city": "Ceršak",
      "latitude": 46.69917,
      "longitude": 15.66917
    },
    {
      "zip": 2326,
      "city": "Cirkovce",
      "latitude": 46.39988,
      "longitude": 15.73118
    },
    {
      "zip": 2282,
      "city": "Cirkulane",
      "latitude": 46.34408,
      "longitude": 15.99472
    },
    {
      "zip": 5273,
      "city": "Col",
      "latitude": 45.87583,
      "longitude": 14.005
    },
    {
      "zip": 8251,
      "city": "Čatež ob Savi",
      "latitude": 45.88944,
      "longitude": 15.6025
    },
    {
      "zip": 1413,
      "city": "Čemšenik",
      "latitude": 46.17917,
      "longitude": 14.94444
    },
    {
      "zip": 5253,
      "city": "Čepovan",
      "latitude": 46.04944,
      "longitude": 13.79694
    },
    {
      "zip": 9232,
      "city": "Črenšovci",
      "latitude": 46.55794,
      "longitude": 16.3041
    },
    {
      "zip": 2393,
      "city": "Črna na Koroškem",
      "latitude": 46.47045,
      "longitude": 14.85009
    },
    {
      "zip": 6275,
      "city": "Črni Kal",
      "latitude": 45.55,
      "longitude": 13.88
    },
    {
      "zip": 5274,
      "city": "Črni Vrh nad Idrijo",
      "latitude": 45.92417,
      "longitude": 14.04361
    },
    {
      "zip": 5262,
      "city": "Črniče",
      "latitude": 45.91056,
      "longitude": 13.77667
    },
    {
      "zip": 8340,
      "city": "Črnomelj",
      "latitude": 45.57111,
      "longitude": 15.18889
    },
    {
      "zip": 6271,
      "city": "Dekani",
      "latitude": 45.54972,
      "longitude": 13.81361
    },
    {
      "zip": 5210,
      "city": "Deskle",
      "latitude": 46.05307,
      "longitude": 13.61382
    },
    {
      "zip": 2253,
      "city": "Destrnik",
      "latitude": 46.49254,
      "longitude": 15.87893
    },
    {
      "zip": 6215,
      "city": "Divača",
      "latitude": 45.68472,
      "longitude": 13.97028
    },
    {
      "zip": 1233,
      "city": "Dob",
      "latitude": 46.15194,
      "longitude": 14.62861
    },
    {
      "zip": 3224,
      "city": "Dobje pri Planini",
      "latitude": 46.13747,
      "longitude": 15.39412
    },
    {
      "zip": 8257,
      "city": "Dobova",
      "latitude": 45.89716,
      "longitude": 15.66371
    },
    {
      "zip": 1423,
      "city": "Dobovec",
      "latitude": 46.11306,
      "longitude": 15.05722
    },
    {
      "zip": 5263,
      "city": "Dobravlje",
      "latitude": 45.88389,
      "longitude": 13.84528
    },
    {
      "zip": 3204,
      "city": "Dobrna",
      "latitude": 46.3375,
      "longitude": 15.22639
    },
    {
      "zip": 8211,
      "city": "Dobrnič",
      "latitude": 45.875,
      "longitude": 14.98
    },
    {
      "zip": 1356,
      "city": "Dobrova",
      "latitude": 46.0458,
      "longitude": 14.39186
    },
    {
      "zip": 9223,
      "city": "Dobrovnik",
      "latitude": 46.65139,
      "longitude": 16.3525
    },
    {
      "zip": 5212,
      "city": "Dobrovo",
      "latitude": 45.99639,
      "longitude": 13.52639
    },
    {
      "zip": 1431,
      "city": "Dol pri Hrastniku",
      "latitude": 46.14194,
      "longitude": 15.11278
    },
    {
      "zip": 1262,
      "city": "Dol pri Ljubljani",
      "latitude": 46.08861,
      "longitude": 14.60083
    },
    {
      "zip": 1273,
      "city": "Dole pri Litiji",
      "latitude": 46.03528,
      "longitude": 15.01889
    },
    {
      "zip": 1331,
      "city": "Dolenja vas",
      "latitude": 46.21667,
      "longitude": 14.23333
    },
    {
      "zip": 8350,
      "city": "Dolenjske Toplice",
      "latitude": 45.75657,
      "longitude": 15.05917
    },
    {
      "zip": 1230,
      "city": "Domžale",
      "latitude": 46.13774,
      "longitude": 14.59371
    },
    {
      "zip": 2252,
      "city": "Dornava",
      "latitude": 46.43667,
      "longitude": 15.95361
    },
    {
      "zip": 5294,
      "city": "Dornberk",
      "latitude": 45.88972,
      "longitude": 13.73694
    },
    {
      "zip": 1319,
      "city": "Draga",
      "latitude": 45.63361,
      "longitude": 14.65472
    },
    {
      "zip": 8343,
      "city": "Dragatuš",
      "latitude": 45.52083,
      "longitude": 15.17556
    },
    {
      "zip": 3222,
      "city": "Dramlje",
      "latitude": 46.27361,
      "longitude": 15.39472
    },
    {
      "zip": 2370,
      "city": "Dravograd",
      "latitude": 46.58333,
      "longitude": 15.03333
    },
    {
      "zip": 4203,
      "city": "Duplje",
      "latitude": 45.86528,
      "longitude": 13.94972
    },
    {
      "zip": 6221,
      "city": "Dutovlje",
      "latitude": 45.75806,
      "longitude": 13.83222
    },
    {
      "zip": 8361,
      "city": "Dvor",
      "latitude": 45.81389,
      "longitude": 14.97111
    },
    {
      "zip": 2343,
      "city": "Fala",
      "latitude": 46.54389,
      "longitude": 15.44722
    },
    {
      "zip": 9208,
      "city": "Fokovci",
      "latitude": 46.73333,
      "longitude": 16.26667
    },
    {
      "zip": 2313,
      "city": "Fram",
      "latitude": 46.456,
      "longitude": 15.63007
    },
    {
      "zip": 3213,
      "city": "Frankolovo",
      "latitude": 46.33056,
      "longitude": 15.31444
    },
    {
      "zip": 1274,
      "city": "Gabrovka",
      "latitude": 45.99951,
      "longitude": 14.98829
    },
    {
      "zip": 8254,
      "city": "Globoko",
      "latitude": 45.955,
      "longitude": 15.62861
    },
    {
      "zip": 5275,
      "city": "Godovič",
      "latitude": 45.95778,
      "longitude": 14.09361
    },
    {
      "zip": 4204,
      "city": "Golnik",
      "latitude": 46.33333,
      "longitude": 14.33333
    },
    {
      "zip": 3303,
      "city": "Gomilsko",
      "latitude": 46.25278,
      "longitude": 15.04222
    },
    {
      "zip": 4224,
      "city": "Gorenja vas",
      "latitude": 46.10722,
      "longitude": 14.14806
    },
    {
      "zip": 3263,
      "city": "Gorica pri Slivnici",
      "latitude": 46.18722,
      "longitude": 15.4325
    },
    {
      "zip": 2272,
      "city": "Gorišnica",
      "latitude": 46.41472,
      "longitude": 16.01389
    },
    {
      "zip": 9250,
      "city": "Gornja Radgona",
      "latitude": 46.63489,
      "longitude": 15.95943
    },
    {
      "zip": 3342,
      "city": "Gornji Grad",
      "latitude": 46.29528,
      "longitude": 14.80833
    },
    {
      "zip": 4282,
      "city": "Gozd Martuljek",
      "latitude": 46.47517,
      "longitude": 13.86368
    },
    {
      "zip": 6272,
      "city": "Gračišče",
      "latitude": 45.505,
      "longitude": 13.87694
    },
    {
      "zip": 9264,
      "city": "Grad",
      "latitude": 46.8,
      "longitude": 16.1
    },
    {
      "zip": 8332,
      "city": "Gradac",
      "latitude": 45.61667,
      "longitude": 15.25
    },
    {
      "zip": 1384,
      "city": "Grahovo",
      "latitude": 45.76917,
      "longitude": 14.42222
    },
    {
      "zip": 5242,
      "city": "Grahovo ob Bači",
      "latitude": 46.15389,
      "longitude": 13.86194
    },
    {
      "zip": 5251,
      "city": "Grgar",
      "latitude": 45.99861,
      "longitude": 13.67389
    },
    {
      "zip": 3302,
      "city": "Griže",
      "latitude": 46.22944,
      "longitude": 15.15056
    },
    {
      "zip": 3231,
      "city": "Grobelno",
      "latitude": 46.21167,
      "longitude": 15.44472
    },
    {
      "zip": 1290,
      "city": "Grosuplje",
      "latitude": 45.93639,
      "longitude": 14.66528
    },
    {
      "zip": 2288,
      "city": "Hajdina",
      "latitude": 46.40318,
      "longitude": 15.85492
    },
    {
      "zip": 8362,
      "city": "Hinje",
      "latitude": 45.76556,
      "longitude": 14.88222
    },
    {
      "zip": 2311,
      "city": "Hoče",
      "latitude": 46.50107,
      "longitude": 15.66291
    },
    {
      "zip": 9205,
      "city": "Hodoš",
      "latitude": 46.82333,
      "longitude": 16.33417
    },
    {
      "zip": 1354,
      "city": "Horjul",
      "latitude": 46.02361,
      "longitude": 14.29917
    },
    {
      "zip": 1372,
      "city": "Hotedršica",
      "latitude": 45.93028,
      "longitude": 14.14222
    },
    {
      "zip": 1430,
      "city": "Hrastnik",
      "latitude": 46.13333,
      "longitude": 15.09972
    },
    {
      "zip": 6225,
      "city": "Hruševje",
      "latitude": 45.76056,
      "longitude": 14.10639
    },
    {
      "zip": 4276,
      "city": "Hrušica",
      "latitude": 46.445,
      "longitude": 14.01778
    },
    {
      "zip": 5280,
      "city": "Idrija",
      "latitude": 46,
      "longitude": 14
    },
    {
      "zip": 1292,
      "city": "Ig",
      "latitude": 45.96028,
      "longitude": 14.52889
    },
    {
      "zip": 6250,
      "city": "Ilirska Bistrica",
      "latitude": 45.5675,
      "longitude": 14.29056
    },
    {
      "zip": 1295,
      "city": "Ivančna Gorica",
      "latitude": 45.93833,
      "longitude": 14.80444
    },
    {
      "zip": 2259,
      "city": "Ivanjkovci",
      "latitude": 46.46222,
      "longitude": 16.14583
    },
    {
      "zip": 1411,
      "city": "Izlake",
      "latitude": 46.15,
      "longitude": 14.95
    },
    {
      "zip": 6310,
      "city": "Izola",
      "latitude": 45.51123,
      "longitude": 13.65655
    },
    {
      "zip": 2222,
      "city": "Jakobski Dol",
      "latitude": 46.61512,
      "longitude": 15.74558
    },
    {
      "zip": 2221,
      "city": "Jarenina",
      "latitude": 46.6361869,
      "longitude": 15.6662933
    },
    {
      "zip": 6254,
      "city": "Jelšane",
      "latitude": 45.50278,
      "longitude": 14.27167
    },
    {
      "zip": 4270,
      "city": "Jesenice",
      "latitude": 46.45253,
      "longitude": 14.07245
    },
    {
      "zip": 8261,
      "city": "Jesenice na Dolenjskem",
      "latitude": 45.85444,
      "longitude": 15.69111
    },
    {
      "zip": 3273,
      "city": "Jurklošter",
      "latitude": 46.09167,
      "longitude": 15.33667
    },
    {
      "zip": 2223,
      "city": "Jurovski Dol",
      "latitude": 46.60639,
      "longitude": 15.78472
    },
    {
      "zip": 2256,
      "city": "Juršinci",
      "latitude": 46.48472,
      "longitude": 15.97139
    },
    {
      "zip": 5214,
      "city": "Kal nad Kanalom",
      "latitude": 46.08333,
      "longitude": 13.74028
    },
    {
      "zip": 3233,
      "city": "Kalobje",
      "latitude": 46.16722,
      "longitude": 15.39444
    },
    {
      "zip": 4246,
      "city": "Kamna Gorica",
      "latitude": 46.31667,
      "longitude": 14.2
    },
    {
      "zip": 2351,
      "city": "Kamnica",
      "latitude": 46.57444,
      "longitude": 15.61417
    },
    {
      "zip": 1241,
      "city": "Kamnik",
      "latitude": 46.24406,
      "longitude": 14.66721
    },
    {
      "zip": 5213,
      "city": "Kanal",
      "latitude": 46.08861,
      "longitude": 13.63972
    },
    {
      "zip": 8258,
      "city": "Kapele",
      "latitude": 45.93333,
      "longitude": 15.68333
    },
    {
      "zip": 2362,
      "city": "Kapla",
      "latitude": 46.24833,
      "longitude": 15.00944
    },
    {
      "zip": 2325,
      "city": "Kidričevo",
      "latitude": 46.40361,
      "longitude": 15.79111
    },
    {
      "zip": 1412,
      "city": "Kisovec",
      "latitude": 46.13911,
      "longitude": 14.96295
    },
    {
      "zip": 6253,
      "city": "Knežak",
      "latitude": 45.62454,
      "longitude": 14.2448
    },
    {
      "zip": 5222,
      "city": "Kobarid",
      "latitude": 46.24761,
      "longitude": 13.57907
    },
    {
      "zip": 9227,
      "city": "Kobilje",
      "latitude": 46.68472,
      "longitude": 16.39778
    },
    {
      "zip": 1330,
      "city": "Kočevje",
      "latitude": 45.64333,
      "longitude": 14.86333
    },
    {
      "zip": 1338,
      "city": "Kočevska Reka",
      "latitude": 45.57639,
      "longitude": 14.79694
    },
    {
      "zip": 2276,
      "city": "Kog",
      "latitude": 46.47167,
      "longitude": 16.25083
    },
    {
      "zip": 5211,
      "city": "Kojsko",
      "latitude": 46.00917,
      "longitude": 13.57972
    },
    {
      "zip": 6223,
      "city": "Komen",
      "latitude": 45.81528,
      "longitude": 13.74833
    },
    {
      "zip": 1218,
      "city": "Komenda",
      "latitude": 46.20241,
      "longitude": 14.54456
    },
    {
      "zip": 6000,
      "city": "Koper",
      "latitude": 45.51833,
      "longitude": 13.82667
    },
    {
      "zip": 8282,
      "city": "Koprivnica",
      "latitude": 46.23306,
      "longitude": 15.24417
    },
    {
      "zip": 5296,
      "city": "Kostanjevica na Krasu",
      "latitude": 45.845,
      "longitude": 13.64167
    },
    {
      "zip": 8311,
      "city": "Kostanjevica na Krki",
      "latitude": 45.84611,
      "longitude": 15.42222
    },
    {
      "zip": 1336,
      "city": "Kostel",
      "latitude": 45.48944,
      "longitude": 14.87111
    },
    {
      "zip": 6256,
      "city": "Košana",
      "latitude": 45.6705,
      "longitude": 14.10563
    },
    {
      "zip": 2394,
      "city": "Kotlje",
      "latitude": 46.52139,
      "longitude": 14.98694
    },
    {
      "zip": 6240,
      "city": "Kozina",
      "latitude": 45.61,
      "longitude": 13.93556
    },
    {
      "zip": 3260,
      "city": "Kozje",
      "latitude": 46.075,
      "longitude": 15.56028
    },
    {
      "zip": 4000,
      "city": "Kranj",
      "latitude": 46.24691,
      "longitude": 14.35646
    },
    {
      "zip": 4280,
      "city": "Kranjska Gora",
      "latitude": 46.45689,
      "longitude": 13.77824
    },
    {
      "zip": 1281,
      "city": "Kresnice",
      "latitude": 46.10204,
      "longitude": 14.78575
    },
    {
      "zip": 4294,
      "city": "Križe",
      "latitude": 46.34278,
      "longitude": 14.30139
    },
    {
      "zip": 9206,
      "city": "Križevci",
      "latitude": 46.78889,
      "longitude": 16.24694
    },
    {
      "zip": 9242,
      "city": "Križevci pri Ljutomeru",
      "latitude": 46.56833,
      "longitude": 16.13861
    },
    {
      "zip": 1301,
      "city": "Krka",
      "latitude": 45.89611,
      "longitude": 15.59833
    },
    {
      "zip": 8296,
      "city": "Krmelj",
      "latitude": 45.98472,
      "longitude": 15.18944
    },
    {
      "zip": 4245,
      "city": "Kropa",
      "latitude": 46.3,
      "longitude": 14.2
    },
    {
      "zip": 8262,
      "city": "Krška vas",
      "latitude": 45.9,
      "longitude": 15.56667
    },
    {
      "zip": 8270,
      "city": "Krško",
      "latitude": 45.95619,
      "longitude": 15.49315
    },
    {
      "zip": 9263,
      "city": "Kuzma",
      "latitude": 46.83694,
      "longitude": 16.08333
    },
    {
      "zip": 2318,
      "city": "Laporje",
      "latitude": 46.34667,
      "longitude": 15.59472
    },
    {
      "zip": 3270,
      "city": "Laško",
      "latitude": 46.15463,
      "longitude": 15.23555
    },
    {
      "zip": 1219,
      "city": "Laze v Tuhinju",
      "latitude": 46.21944,
      "longitude": 14.76111
    },
    {
      "zip": 2230,
      "city": "Lenart v Slovenskih goricah",
      "latitude": 46.57611,
      "longitude": 15.83139
    },
    {
      "zip": 9220,
      "city": "Lendava",
      "latitude": 46.56494,
      "longitude": 16.45091
    },
    {
      "zip": 4248,
      "city": "Lesce",
      "latitude": 46.36111,
      "longitude": 14.15778
    },
    {
      "zip": 3261,
      "city": "Lesično",
      "latitude": 46.1025,
      "longitude": 15.51417
    },
    {
      "zip": 8273,
      "city": "Leskovec pri Krškem",
      "latitude": 45.93566,
      "longitude": 15.47184
    },
    {
      "zip": 2372,
      "city": "Libeliče",
      "latitude": 46.62,
      "longitude": 14.94972
    },
    {
      "zip": 2341,
      "city": "Limbuš",
      "latitude": 46.55361,
      "longitude": 15.58361
    },
    {
      "zip": 1270,
      "city": "Litija",
      "latitude": 46.07445,
      "longitude": 14.90724
    },
    {
      "zip": 3202,
      "city": "Ljubečna",
      "latitude": 46.25567,
      "longitude": 15.3243
    },
    {
      "zip": 1000,
      "city": "Ljubljana",
      "latitude": 46.05108,
      "longitude": 14.50513
    },
    {
      "zip": 3333,
      "city": "Ljubno ob Savinji",
      "latitude": 46.34358,
      "longitude": 14.83377
    },
    {
      "zip": 9240,
      "city": "Ljutomer",
      "latitude": 46.51347,
      "longitude": 16.144
    },
    {
      "zip": 3215,
      "city": "Loče",
      "latitude": 46.29972,
      "longitude": 15.5
    },
    {
      "zip": 5231,
      "city": "Log pod Mangartom",
      "latitude": 46.40194,
      "longitude": 13.59694
    },
    {
      "zip": 1358,
      "city": "Log pri Brezovici",
      "latitude": 46.01667,
      "longitude": 14.36667
    },
    {
      "zip": 1370,
      "city": "Logatec",
      "latitude": 45.92139,
      "longitude": 14.19583
    },
    {
      "zip": 1371,
      "city": "Logatec",
      "latitude": 45.92139,
      "longitude": 14.19583
    },
    {
      "zip": 1434,
      "city": "Loka pri Zidanem Mostu",
      "latitude": 46.0575,
      "longitude": 15.20722
    },
    {
      "zip": 3223,
      "city": "Loka pri Žusmu",
      "latitude": 46.1575,
      "longitude": 15.51472
    },
    {
      "zip": 6219,
      "city": "Lokev",
      "latitude": 45.66111,
      "longitude": 13.92806
    },
    {
      "zip": 1318,
      "city": "Loški Potok",
      "latitude": 45.70139,
      "longitude": 14.58028
    },
    {
      "zip": 2324,
      "city": "Lovrenc na Dravskem polju",
      "latitude": 46.37361,
      "longitude": 15.77917
    },
    {
      "zip": 2344,
      "city": "Lovrenc na Pohorju",
      "latitude": 46.54056,
      "longitude": 15.39306
    },
    {
      "zip": 3334,
      "city": "Luče",
      "latitude": 46.35611,
      "longitude": 14.74667
    },
    {
      "zip": 1225,
      "city": "Lukovica",
      "latitude": 46.18333,
      "longitude": 14.76861
    },
    {
      "zip": 9202,
      "city": "Mačkovci",
      "latitude": 46.78139,
      "longitude": 16.16889
    },
    {
      "zip": 2322,
      "city": "Majšperk",
      "latitude": 46.35167,
      "longitude": 15.73361
    },
    {
      "zip": 2321,
      "city": "Makole",
      "latitude": 46.31722,
      "longitude": 15.66722
    },
    {
      "zip": 9243,
      "city": "Mala Nedelja",
      "latitude": 46.52889,
      "longitude": 16.05583
    },
    {
      "zip": 2229,
      "city": "Malečnik",
      "latitude": 46.55417,
      "longitude": 15.70278
    },
    {
      "zip": 6273,
      "city": "Marezige",
      "latitude": 45.50972,
      "longitude": 13.80306
    },
    {
      "zip": 2000,
      "city": "Maribor",
      "latitude": 46.55611,
      "longitude": 15.64306
    },
    {
      "zip": 2206,
      "city": "Marjeta na Dravskem polju",
      "latitude": 46.4525,
      "longitude": 15.73694
    },
    {
      "zip": 2281,
      "city": "Markovci",
      "latitude": 46.84444,
      "longitude": 16.23361
    },
    {
      "zip": 9221,
      "city": "Martjanci",
      "latitude": 46.68639,
      "longitude": 16.19778
    },
    {
      "zip": 6242,
      "city": "Materija",
      "latitude": 45.58139,
      "longitude": 13.99667
    },
    {
      "zip": 4211,
      "city": "Mavčiče",
      "latitude": 46.18179,
      "longitude": 14.39484
    },
    {
      "zip": 1215,
      "city": "Medvode",
      "latitude": 46.1422,
      "longitude": 14.41114
    },
    {
      "zip": 1234,
      "city": "Mengeš",
      "latitude": 46.16694,
      "longitude": 14.575
    },
    {
      "zip": 8330,
      "city": "Metlika",
      "latitude": 45.65645,
      "longitude": 15.31075
    },
    {
      "zip": 2392,
      "city": "Mežica",
      "latitude": 46.52139,
      "longitude": 14.85444
    },
    {
      "zip": 2204,
      "city": "Miklavž na Dravskem polju",
      "latitude": 46.50583,
      "longitude": 15.69722
    },
    {
      "zip": 2275,
      "city": "Miklavž pri Ormožu",
      "latitude": 46.46,
      "longitude": 16.21361
    },
    {
      "zip": 5291,
      "city": "Miren",
      "latitude": 45.89556,
      "longitude": 13.6075
    },
    {
      "zip": 8233,
      "city": "Mirna",
      "latitude": 46.01667,
      "longitude": 15.3
    },
    {
      "zip": 8216,
      "city": "Mirna Peč",
      "latitude": 45.86028,
      "longitude": 15.08333
    },
    {
      "zip": 2382,
      "city": "Mislinja",
      "latitude": 46.57667,
      "longitude": 15.0275
    },
    {
      "zip": 4281,
      "city": "Mojstrana",
      "latitude": 46.42383,
      "longitude": 13.8752
    },
    {
      "zip": 8230,
      "city": "Mokronog",
      "latitude": 45.93417,
      "longitude": 15.14083
    },
    {
      "zip": 1251,
      "city": "Moravče",
      "latitude": 46.13694,
      "longitude": 14.745
    },
    {
      "zip": 9226,
      "city": "Moravske Toplice",
      "latitude": 46.68313,
      "longitude": 16.2208
    },
    {
      "zip": 5216,
      "city": "Most na Soči",
      "latitude": 46.14694,
      "longitude": 13.73889
    },
    {
      "zip": 1221,
      "city": "Motnik",
      "latitude": 46.21528,
      "longitude": 14.89111
    },
    {
      "zip": 3330,
      "city": "Mozirje",
      "latitude": 46.3612,
      "longitude": 14.95797
    },
    {
      "zip": 9000,
      "city": "Murska Sobota",
      "latitude": 46.65139,
      "longitude": 16.16056
    },
    {
      "zip": 2366,
      "city": "Muta",
      "latitude": 46.61139,
      "longitude": 15.16611
    },
    {
      "zip": 4202,
      "city": "Naklo",
      "latitude": 46.27278,
      "longitude": 14.31722
    },
    {
      "zip": 3331,
      "city": "Nazarje",
      "latitude": 46.28167,
      "longitude": 14.90917
    },
    {
      "zip": 1357,
      "city": "Notranje Gorice",
      "latitude": 45.9875,
      "longitude": 14.39889
    },
    {
      "zip": 3203,
      "city": "Nova Cerkev",
      "latitude": 46.31278,
      "longitude": 15.28556
    },
    {
      "zip": 5000,
      "city": "Nova Gorica",
      "latitude": 45.9652,
      "longitude": 13.72805
    },
    {
      "zip": 1385,
      "city": "Nova vas",
      "latitude": 45.92544,
      "longitude": 14.7443
    },
    {
      "zip": 8000,
      "city": "Novo mesto",
      "latitude": 45.80397,
      "longitude": 15.16886
    },
    {
      "zip": 6243,
      "city": "Obrov",
      "latitude": 45.5425,
      "longitude": 14.08694
    },
    {
      "zip": 9233,
      "city": "Odranci",
      "latitude": 46.58667,
      "longitude": 16.28028
    },
    {
      "zip": 2317,
      "city": "Oplotnica",
      "latitude": 46.32722,
      "longitude": 15.49583
    },
    {
      "zip": 2312,
      "city": "Orehova vas",
      "latitude": 46.47274,
      "longitude": 15.66353
    },
    {
      "zip": 2270,
      "city": "Ormož",
      "latitude": 46.41139,
      "longitude": 16.15444
    },
    {
      "zip": 1316,
      "city": "Ortnek",
      "latitude": 45.78196,
      "longitude": 14.67531
    },
    {
      "zip": 1337,
      "city": "Osilnica",
      "latitude": 45.52914,
      "longitude": 14.69838
    },
    {
      "zip": 8222,
      "city": "Otočec",
      "latitude": 45.83967,
      "longitude": 15.22528
    },
    {
      "zip": 2361,
      "city": "Ožbalt",
      "latitude": 46.58694,
      "longitude": 15.39889
    },
    {
      "zip": 2231,
      "city": "Pernica",
      "latitude": 46.58,
      "longitude": 15.72778
    },
    {
      "zip": 2211,
      "city": "Pesnica pri Mariboru",
      "latitude": 46.60694,
      "longitude": 15.67667
    },
    {
      "zip": 9203,
      "city": "Petrovci",
      "latitude": 46.80528,
      "longitude": 16.2225
    },
    {
      "zip": 3301,
      "city": "Petrovče",
      "latitude": 46.24167,
      "longitude": 15.19
    },
    {
      "zip": 6330,
      "city": "Piran",
      "latitude": 45.48278,
      "longitude": 13.62694
    },
    {
      "zip": 8255,
      "city": "Pišece",
      "latitude": 46.0031,
      "longitude": 15.6459
    },
    {
      "zip": 6257,
      "city": "Pivka",
      "latitude": 45.59944,
      "longitude": 14.385
    },
    {
      "zip": 6232,
      "city": "Planina",
      "latitude": 45.86168,
      "longitude": 14.27491
    },
    {
      "zip": 3225,
      "city": "Planina pri Sevnici",
      "latitude": 46.11139,
      "longitude": 15.40944
    },
    {
      "zip": 6276,
      "city": "Pobegi",
      "latitude": 45.53944,
      "longitude": 13.79611
    },
    {
      "zip": 8312,
      "city": "Podbočje",
      "latitude": 45.86667,
      "longitude": 15.46667
    },
    {
      "zip": 5243,
      "city": "Podbrdo",
      "latitude": 46.21,
      "longitude": 13.96444
    },
    {
      "zip": 3254,
      "city": "Podčetrtek",
      "latitude": 46.15694,
      "longitude": 15.59861
    },
    {
      "zip": 2273,
      "city": "Podgorci",
      "latitude": 46.42917,
      "longitude": 16.06361
    },
    {
      "zip": 6216,
      "city": "Podgorje",
      "latitude": 46.7,
      "longitude": 15.81667
    },
    {
      "zip": 2381,
      "city": "Podgorje pri Slovenj Gradcu",
      "latitude": 46.47194,
      "longitude": 15.08361
    },
    {
      "zip": 6244,
      "city": "Podgrad",
      "latitude": 46.0722,
      "longitude": 14.64968
    },
    {
      "zip": 1414,
      "city": "Podkum",
      "latitude": 46.07139,
      "longitude": 15.02861
    },
    {
      "zip": 2286,
      "city": "Podlehnik",
      "latitude": 46.33528,
      "longitude": 15.88
    },
    {
      "zip": 5272,
      "city": "Podnanos",
      "latitude": 45.79694,
      "longitude": 13.97139
    },
    {
      "zip": 4244,
      "city": "Podnart",
      "latitude": 46.29523,
      "longitude": 14.25573
    },
    {
      "zip": 3241,
      "city": "Podplat",
      "latitude": 46.25,
      "longitude": 15.7
    },
    {
      "zip": 3257,
      "city": "Podsreda",
      "latitude": 46.04139,
      "longitude": 15.59056
    },
    {
      "zip": 2363,
      "city": "Podvelka",
      "latitude": 46.58694,
      "longitude": 15.33056
    },
    {
      "zip": 2208,
      "city": "Pohorje",
      "latitude": 46.53333,
      "longitude": 15.46667
    },
    {
      "zip": 2257,
      "city": "Polenšak",
      "latitude": 46.47457,
      "longitude": 16.00309
    },
    {
      "zip": 1355,
      "city": "Polhov Gradec",
      "latitude": 46.06694,
      "longitude": 14.31611
    },
    {
      "zip": 4223,
      "city": "Poljane nad Škofjo Loko",
      "latitude": 46.12099,
      "longitude": 14.18622
    },
    {
      "zip": 2319,
      "city": "Poljčane",
      "latitude": 46.31194,
      "longitude": 15.57917
    },
    {
      "zip": 1272,
      "city": "Polšnik",
      "latitude": 46.06667,
      "longitude": 14.95
    },
    {
      "zip": 3313,
      "city": "Polzela",
      "latitude": 46.28333,
      "longitude": 15.06667
    },
    {
      "zip": 3232,
      "city": "Ponikva",
      "latitude": 46.25306,
      "longitude": 15.44694
    },
    {
      "zip": 6320,
      "city": "Portorož",
      "latitude": 45.51429,
      "longitude": 13.59206
    },
    {
      "zip": 6230,
      "city": "Postojna",
      "latitude": 45.78556,
      "longitude": 14.17083
    },
    {
      "zip": 2331,
      "city": "Pragersko",
      "latitude": 46.39667,
      "longitude": 15.66
    },
    {
      "zip": 3312,
      "city": "Prebold",
      "latitude": 46.23694,
      "longitude": 15.0925
    },
    {
      "zip": 4205,
      "city": "Preddvor",
      "latitude": 46.3025,
      "longitude": 14.42306
    },
    {
      "zip": 6255,
      "city": "Prem",
      "latitude": 45.60479,
      "longitude": 14.18344
    },
    {
      "zip": 1352,
      "city": "Preserje",
      "latitude": 45.95694,
      "longitude": 14.41889
    },
    {
      "zip": 6258,
      "city": "Prestranek",
      "latitude": 45.73033,
      "longitude": 14.18622
    },
    {
      "zip": 2391,
      "city": "Prevalje",
      "latitude": 46.54694,
      "longitude": 14.92083
    },
    {
      "zip": 3262,
      "city": "Prevorje",
      "latitude": 46.1248136,
      "longitude": 15.4634155
    },
    {
      "zip": 1276,
      "city": "Primskovo",
      "latitude": 45.97173,
      "longitude": 14.91038
    },
    {
      "zip": 3253,
      "city": "Pristava pri Mestinju",
      "latitude": 46.19667,
      "longitude": 15.59611
    },
    {
      "zip": 9207,
      "city": "Prosenjakovci",
      "latitude": 46.74083,
      "longitude": 16.3175
    },
    {
      "zip": 5297,
      "city": "Prvačina",
      "latitude": 45.89,
      "longitude": 13.71806
    },
    {
      "zip": 2250,
      "city": "Ptuj",
      "latitude": 46.43417,
      "longitude": 15.87667
    },
    {
      "zip": 2323,
      "city": "Ptujska Gora",
      "latitude": 46.355,
      "longitude": 15.76
    },
    {
      "zip": 9201,
      "city": "Puconci",
      "latitude": 46.70667,
      "longitude": 16.15639
    },
    {
      "zip": 2327,
      "city": "Rače",
      "latitude": 46.45194,
      "longitude": 15.68139
    },
    {
      "zip": 1433,
      "city": "Radeče",
      "latitude": 46.06806,
      "longitude": 15.18389
    },
    {
      "zip": 9252,
      "city": "Radenci",
      "latitude": 46.64201,
      "longitude": 16.03781
    },
    {
      "zip": 2360,
      "city": "Radlje ob Dravi",
      "latitude": 46.60725,
      "longitude": 15.25093
    },
    {
      "zip": 1235,
      "city": "Radomlje",
      "latitude": 46.17361,
      "longitude": 14.61222
    },
    {
      "zip": 4240,
      "city": "Radovljica",
      "latitude": 46.33778,
      "longitude": 14.19417
    },
    {
      "zip": 8274,
      "city": "Raka",
      "latitude": 45.92972,
      "longitude": 15.38417
    },
    {
      "zip": 1381,
      "city": "Rakek",
      "latitude": 45.81333,
      "longitude": 14.31111
    },
    {
      "zip": 4283,
      "city": "Rateče - Planica",
      "latitude": 46.47333,
      "longitude": 13.72417
    },
    {
      "zip": 2390,
      "city": "Ravne na Koroškem",
      "latitude": 46.54306,
      "longitude": 14.96917
    },
    {
      "zip": 9246,
      "city": "Razkrižje",
      "latitude": 46.52167,
      "longitude": 16.28111
    },
    {
      "zip": 3332,
      "city": "Rečica ob Savinji",
      "latitude": 46.31667,
      "longitude": 14.91667
    },
    {
      "zip": 5292,
      "city": "Renče",
      "latitude": 45.89,
      "longitude": 13.66861
    },
    {
      "zip": 1310,
      "city": "Ribnica",
      "latitude": 45.73738,
      "longitude": 14.71605
    },
    {
      "zip": 2364,
      "city": "Ribnica na Pohorju",
      "latitude": 46.535,
      "longitude": 15.27278
    },
    {
      "zip": 3272,
      "city": "Rimske Toplice",
      "latitude": 46.1226,
      "longitude": 15.1919
    },
    {
      "zip": 1314,
      "city": "Rob",
      "latitude": 45.84861,
      "longitude": 14.57222
    },
    {
      "zip": 5215,
      "city": "Ročinj",
      "latitude": 46.11278,
      "longitude": 13.67
    },
    {
      "zip": 3250,
      "city": "Rogaška Slatina",
      "latitude": 46.2375,
      "longitude": 15.63972
    },
    {
      "zip": 9262,
      "city": "Rogašovci",
      "latitude": 46.8,
      "longitude": 16.03333
    },
    {
      "zip": 3252,
      "city": "Rogatec",
      "latitude": 46.22944,
      "longitude": 15.70028
    },
    {
      "zip": 1373,
      "city": "Rovte",
      "latitude": 45.98528,
      "longitude": 14.1775
    },
    {
      "zip": 2342,
      "city": "Ruše",
      "latitude": 46.53944,
      "longitude": 15.51583
    },
    {
      "zip": 1282,
      "city": "Sava",
      "latitude": 46.08984,
      "longitude": 14.89037
    },
    {
      "zip": 6333,
      "city": "Sečovlje",
      "latitude": 45.47667,
      "longitude": 13.62333
    },
    {
      "zip": 4227,
      "city": "Selca",
      "latitude": 46.22444,
      "longitude": 14.20556
    },
    {
      "zip": 2352,
      "city": "Selnica ob Dravi",
      "latitude": 46.55,
      "longitude": 15.495
    },
    {
      "zip": 8333,
      "city": "Semič",
      "latitude": 45.64611,
      "longitude": 15.18222
    },
    {
      "zip": 8281,
      "city": "Senovo",
      "latitude": 46.02361,
      "longitude": 15.47694
    },
    {
      "zip": 6224,
      "city": "Senožeče",
      "latitude": 45.72111,
      "longitude": 14.04111
    },
    {
      "zip": 8290,
      "city": "Sevnica",
      "latitude": 46,
      "longitude": 15.3
    },
    {
      "zip": 6210,
      "city": "Sežana",
      "latitude": 45.70924,
      "longitude": 13.87333
    },
    {
      "zip": 2214,
      "city": "Sladki Vrh",
      "latitude": 46.68892,
      "longitude": 15.73719
    },
    {
      "zip": 5283,
      "city": "Slap ob Idrijci",
      "latitude": 46.12333,
      "longitude": 13.80472
    },
    {
      "zip": 2380,
      "city": "Slovenj Gradec",
      "latitude": 46.48861,
      "longitude": 15.08361
    },
    {
      "zip": 2310,
      "city": "Slovenska Bistrica",
      "latitude": 46.42516,
      "longitude": 15.50667
    },
    {
      "zip": 3210,
      "city": "Slovenske Konjice",
      "latitude": 46.325,
      "longitude": 15.45556
    },
    {
      "zip": 1216,
      "city": "Smlednik",
      "latitude": 46.16417,
      "longitude": 14.4325
    },
    {
      "zip": 5232,
      "city": "Soča",
      "latitude": 46.33236,
      "longitude": 13.68848
    },
    {
      "zip": 1317,
      "city": "Sodražica",
      "latitude": 45.76111,
      "longitude": 14.63556
    },
    {
      "zip": 3335,
      "city": "Solčava",
      "latitude": 46.41944,
      "longitude": 14.69361
    },
    {
      "zip": 5250,
      "city": "Solkan",
      "latitude": 45.97139,
      "longitude": 13.64944
    },
    {
      "zip": 4229,
      "city": "Sorica",
      "latitude": 46.21889,
      "longitude": 14.03028
    },
    {
      "zip": 4225,
      "city": "Sovodenj",
      "latitude": 46.08639,
      "longitude": 14.04361
    },
    {
      "zip": 5281,
      "city": "Spodnja Idrija",
      "latitude": 46.03194,
      "longitude": 14.02722
    },
    {
      "zip": 2241,
      "city": "Spodnji Duplek",
      "latitude": 46.50306,
      "longitude": 15.74528
    },
    {
      "zip": 9245,
      "city": "Spodnji Ivanjci",
      "latitude": 46.59556,
      "longitude": 15.98139
    },
    {
      "zip": 2277,
      "city": "Središče ob Dravi",
      "latitude": 46.39417,
      "longitude": 16.26806
    },
    {
      "zip": 4267,
      "city": "Srednja vas v Bohinju",
      "latitude": 46.295,
      "longitude": 13.92389
    },
    {
      "zip": 8256,
      "city": "Sromlje",
      "latitude": 45.98333,
      "longitude": 15.6
    },
    {
      "zip": 5224,
      "city": "Srpenica",
      "latitude": 46.29167,
      "longitude": 13.49876
    },
    {
      "zip": 1242,
      "city": "Stahovica",
      "latitude": 46.27683,
      "longitude": 14.61719
    },
    {
      "zip": 1332,
      "city": "Stara Cerkev",
      "latitude": 45.67218,
      "longitude": 14.83975
    },
    {
      "zip": 8342,
      "city": "Stari trg ob Kolpi",
      "latitude": 45.49359,
      "longitude": 15.07719
    },
    {
      "zip": 1386,
      "city": "Stari trg pri Ložu",
      "latitude": 45.71484,
      "longitude": 14.47097
    },
    {
      "zip": 2205,
      "city": "Starše",
      "latitude": 46.46583,
      "longitude": 15.76722
    },
    {
      "zip": 2289,
      "city": "Stoperce",
      "latitude": 46.29,
      "longitude": 15.71667
    },
    {
      "zip": 8322,
      "city": "Stopiče",
      "latitude": 45.76944,
      "longitude": 15.20967
    },
    {
      "zip": 3206,
      "city": "Stranice",
      "latitude": 46.36361,
      "longitude": 15.36528
    },
    {
      "zip": 8351,
      "city": "Straža",
      "latitude": 45.78,
      "longitude": 15.07278
    },
    {
      "zip": 1313,
      "city": "Struge",
      "latitude": 45.78704,
      "longitude": 14.7579
    },
    {
      "zip": 8293,
      "city": "Studenec",
      "latitude": 46.06667,
      "longitude": 14.58333
    },
    {
      "zip": 8331,
      "city": "Suhor",
      "latitude": 45.52958,
      "longitude": 14.87077
    },
    {
      "zip": 2233,
      "city": "Sv. Ana v Slovenskih goricah",
      "latitude": 46.64917,
      "longitude": 15.84417
    },
    {
      "zip": 2353,
      "city": "Sv. Duh na Ostrem Vrhu",
      "latitude": 46.61457,
      "longitude": 15.46128
    },
    {
      "zip": 2235,
      "city": "Sv. Trojica v Slovenskih goricah",
      "latitude": 46.57667,
      "longitude": 15.87694
    },
    {
      "zip": 9244,
      "city": "Sveti Jurij ob Ščavnici",
      "latitude": 46.5695,
      "longitude": 16.02347
    },
    {
      "zip": 3264,
      "city": "Sveti Štefan",
      "latitude": 46.18625,
      "longitude": 15.49325
    },
    {
      "zip": 2258,
      "city": "Sveti Tomaž",
      "latitude": 46.48417,
      "longitude": 16.08361
    },
    {
      "zip": 9204,
      "city": "Šalovci",
      "latitude": 46.825,
      "longitude": 16.29806
    },
    {
      "zip": 5261,
      "city": "Šempas",
      "latitude": 45.93028,
      "longitude": 13.74361
    },
    {
      "zip": 5290,
      "city": "Šempeter pri Gorici",
      "latitude": 45.9275,
      "longitude": 13.64111
    },
    {
      "zip": 3311,
      "city": "Šempeter v Savinjski dolini",
      "latitude": 46.26056,
      "longitude": 15.11247
    },
    {
      "zip": 4208,
      "city": "Šenčur",
      "latitude": 46.24556,
      "longitude": 14.41972
    },
    {
      "zip": 2212,
      "city": "Šentilj v Slovenskih goricah",
      "latitude": 46.67921,
      "longitude": 15.65324
    },
    {
      "zip": 8297,
      "city": "Šentjanž",
      "latitude": 46.31638,
      "longitude": 14.89692
    },
    {
      "zip": 2373,
      "city": "Šentjanž pri Dravogradu",
      "latitude": 46.56111,
      "longitude": 15.03889
    },
    {
      "zip": 8310,
      "city": "Šentjernej",
      "latitude": 45.84,
      "longitude": 15.33611
    },
    {
      "zip": 3230,
      "city": "Šentjur",
      "latitude": 46.21722,
      "longitude": 15.3975
    },
    {
      "zip": 3271,
      "city": "Šentrupert",
      "latitude": 46.25,
      "longitude": 15.06667
    },
    {
      "zip": 8232,
      "city": "Šentrupert",
      "latitude": 46.25,
      "longitude": 15.06667
    },
    {
      "zip": 1296,
      "city": "Šentvid pri Stični",
      "latitude": 45.95004,
      "longitude": 14.84344
    },
    {
      "zip": 8275,
      "city": "Škocjan",
      "latitude": 45.88481,
      "longitude": 14.63139
    },
    {
      "zip": 6281,
      "city": "Škofije",
      "latitude": 45.56639,
      "longitude": 13.79944
    },
    {
      "zip": 4220,
      "city": "Škofja Loka",
      "latitude": 46.16551,
      "longitude": 14.30626
    },
    {
      "zip": 3211,
      "city": "Škofja vas",
      "latitude": 46.26792,
      "longitude": 15.29117
    },
    {
      "zip": 1291,
      "city": "Škofljica",
      "latitude": 45.98333,
      "longitude": 14.57667
    },
    {
      "zip": 6274,
      "city": "Šmarje",
      "latitude": 46.01667,
      "longitude": 15.31667
    },
    {
      "zip": 3240,
      "city": "Šmarje pri Jelšah",
      "latitude": 46.22722,
      "longitude": 15.51917
    },
    {
      "zip": 1293,
      "city": "Šmarje-Sap",
      "latitude": 45.97501,
      "longitude": 14.61735
    },
    {
      "zip": 8220,
      "city": "Šmarješke Toplice",
      "latitude": 45.86242,
      "longitude": 15.22188
    },
    {
      "zip": 2315,
      "city": "Šmartno na Pohorju",
      "latitude": 46.44417,
      "longitude": 15.53944
    },
    {
      "zip": 3341,
      "city": "Šmartno ob Dreti",
      "latitude": 46.28389,
      "longitude": 14.89028
    },
    {
      "zip": 3327,
      "city": "Šmartno ob Paki",
      "latitude": 46.33333,
      "longitude": 15.03333
    },
    {
      "zip": 1275,
      "city": "Šmartno pri Litiji",
      "latitude": 46.04444,
      "longitude": 14.84417
    },
    {
      "zip": 2383,
      "city": "Šmartno pri Slovenj Gradcu",
      "latitude": 46.48944,
      "longitude": 15.10667
    },
    {
      "zip": 3201,
      "city": "Šmartno v Rožni dolini",
      "latitude": 46.29453,
      "longitude": 15.24314
    },
    {
      "zip": 3325,
      "city": "Šoštanj",
      "latitude": 46.38,
      "longitude": 15.04861
    },
    {
      "zip": 6222,
      "city": "Štanjel",
      "latitude": 45.82333,
      "longitude": 13.84361
    },
    {
      "zip": 3220,
      "city": "Štore",
      "latitude": 46.22083,
      "longitude": 15.31389
    },
    {
      "zip": 3304,
      "city": "Tabor",
      "latitude": 46.23611,
      "longitude": 15.01833
    },
    {
      "zip": 3221,
      "city": "Teharje",
      "latitude": 46.23028,
      "longitude": 15.30222
    },
    {
      "zip": 9251,
      "city": "Tišina",
      "latitude": 46.65806,
      "longitude": 16.09167
    },
    {
      "zip": 5220,
      "city": "Tolmin",
      "latitude": 46.18304,
      "longitude": 13.73321
    },
    {
      "zip": 3326,
      "city": "Topolšica",
      "latitude": 46.40028,
      "longitude": 15.02157
    },
    {
      "zip": 2371,
      "city": "Trbonje",
      "latitude": 46.60278,
      "longitude": 15.1075
    },
    {
      "zip": 1420,
      "city": "Trbovlje",
      "latitude": 46.14167,
      "longitude": 15.04222
    },
    {
      "zip": 8231,
      "city": "Trebelno",
      "latitude": 45.91667,
      "longitude": 15.15
    },
    {
      "zip": 8210,
      "city": "Trebnje",
      "latitude": 45.90688,
      "longitude": 15.00857
    },
    {
      "zip": 5252,
      "city": "Trnovo pri Gorici",
      "latitude": 45.97417,
      "longitude": 13.74333
    },
    {
      "zip": 2254,
      "city": "Trnovska vas",
      "latitude": 46.52019,
      "longitude": 15.88657
    },
    {
      "zip": 1222,
      "city": "Trojane",
      "latitude": 46.18833,
      "longitude": 14.88306
    },
    {
      "zip": 1236,
      "city": "Trzin",
      "latitude": 46.13333,
      "longitude": 14.56667
    },
    {
      "zip": 4290,
      "city": "Tržič",
      "latitude": 46.36357,
      "longitude": 14.31046
    },
    {
      "zip": 8295,
      "city": "Tržišče",
      "latitude": 45.95889,
      "longitude": 15.19722
    },
    {
      "zip": 1311,
      "city": "Turjak",
      "latitude": 45.87889,
      "longitude": 14.61528
    },
    {
      "zip": 9224,
      "city": "Turnišče",
      "latitude": 46.62778,
      "longitude": 16.32028
    },
    {
      "zip": 8323,
      "city": "Uršna sela",
      "latitude": 45.71888,
      "longitude": 15.12551
    },
    {
      "zip": 1252,
      "city": "Vače",
      "latitude": 46.12,
      "longitude": 14.83917
    },
    {
      "zip": 3320,
      "city": "Velenje",
      "latitude": 46.36667,
      "longitude": 15.13333
    },
    {
      "zip": 8212,
      "city": "Velika Loka",
      "latitude": 45.92889,
      "longitude": 14.96778
    },
    {
      "zip": 2274,
      "city": "Velika Nedelja",
      "latitude": 46.41778,
      "longitude": 16.10972
    },
    {
      "zip": 9225,
      "city": "Velika Polana",
      "latitude": 46.57194,
      "longitude": 16.34694
    },
    {
      "zip": 1315,
      "city": "Velike Lašče",
      "latitude": 45.83222,
      "longitude": 14.63639
    },
    {
      "zip": 8213,
      "city": "Veliki Gaber",
      "latitude": 45.93385,
      "longitude": 14.90708
    },
    {
      "zip": 9241,
      "city": "Veržej",
      "latitude": 46.58361,
      "longitude": 16.16528
    },
    {
      "zip": 1312,
      "city": "Videm – Dobrepolje",
      "latitude": 45.85834,
      "longitude": 14.68143
    },
    {
      "zip": 2284,
      "city": "Videm pri Ptuju",
      "latitude": 46.36861,
      "longitude": 15.90639
    },
    {
      "zip": 8344,
      "city": "Vinica",
      "latitude": 45.46015,
      "longitude": 15.25396
    },
    {
      "zip": 5271,
      "city": "Vipava",
      "latitude": 45.84667,
      "longitude": 13.96306
    },
    {
      "zip": 4212,
      "city": "Visoko",
      "latitude": 46.27111,
      "longitude": 14.41806
    },
    {
      "zip": 1294,
      "city": "Višnja Gora",
      "latitude": 45.95389,
      "longitude": 14.74528
    },
    {
      "zip": 3205,
      "city": "Vitanje",
      "latitude": 46.38167,
      "longitude": 15.29583
    },
    {
      "zip": 2255,
      "city": "Vitomarci",
      "latitude": 46.5275,
      "longitude": 15.93944
    },
    {
      "zip": 1217,
      "city": "Vodice",
      "latitude": 46.18987,
      "longitude": 14.49492
    },
    {
      "zip": 3212,
      "city": "Vojnik",
      "latitude": 46.29333,
      "longitude": 15.30333
    },
    {
      "zip": 5293,
      "city": "Volčja Draga",
      "latitude": 45.90694,
      "longitude": 13.6775
    },
    {
      "zip": 2232,
      "city": "Voličina",
      "latitude": 46.53333,
      "longitude": 15.78333
    },
    {
      "zip": 3305,
      "city": "Vransko",
      "latitude": 46.24389,
      "longitude": 14.95139
    },
    {
      "zip": 6217,
      "city": "Vremski Britof",
      "latitude": 45.65713,
      "longitude": 14.03014
    },
    {
      "zip": 1360,
      "city": "Vrhnika",
      "latitude": 45.9614,
      "longitude": 14.29265
    },
    {
      "zip": 2365,
      "city": "Vuhred",
      "latitude": 46.59472,
      "longitude": 15.2375
    },
    {
      "zip": 2367,
      "city": "Vuzenica",
      "latitude": 46.59639,
      "longitude": 15.16722
    },
    {
      "zip": 8292,
      "city": "Zabukovje",
      "latitude": 45.98333,
      "longitude": 15.05
    },
    {
      "zip": 1410,
      "city": "Zagorje ob Savi",
      "latitude": 46.12974,
      "longitude": 14.96583
    },
    {
      "zip": 1303,
      "city": "Zagradec",
      "latitude": 45.86139,
      "longitude": 14.83417
    },
    {
      "zip": 2283,
      "city": "Zavrč",
      "latitude": 46.39167,
      "longitude": 16.04972
    },
    {
      "zip": 8272,
      "city": "Zdole",
      "latitude": 46.09056,
      "longitude": 15.55198
    },
    {
      "zip": 4201,
      "city": "Zgornja Besnica",
      "latitude": 46.26667,
      "longitude": 14.28333
    },
    {
      "zip": 2242,
      "city": "Zgornja Korena",
      "latitude": 46.524,
      "longitude": 15.77226
    },
    {
      "zip": 2201,
      "city": "Zgornja Kungota",
      "latitude": 46.63917,
      "longitude": 15.61556
    },
    {
      "zip": 2316,
      "city": "Zgornja Ložnica",
      "latitude": 46.38333,
      "longitude": 15.51667
    },
    {
      "zip": 2314,
      "city": "Zgornja Polskava",
      "latitude": 46.42556,
      "longitude": 15.61222
    },
    {
      "zip": 2213,
      "city": "Zgornja Velka",
      "latitude": 46.67528,
      "longitude": 15.76917
    },
    {
      "zip": 4247,
      "city": "Zgornje Gorje",
      "latitude": 46.37962,
      "longitude": 14.06937
    },
    {
      "zip": 4206,
      "city": "Zgornje Jezersko",
      "latitude": 46.3941,
      "longitude": 14.50659
    },
    {
      "zip": 2285,
      "city": "Zgornji Leskovec",
      "latitude": 46.33028,
      "longitude": 15.93694
    },
    {
      "zip": 1432,
      "city": "Zidani Most",
      "latitude": 46.08823,
      "longitude": 15.17284
    },
    {
      "zip": 3214,
      "city": "Zreče",
      "latitude": 46.38222,
      "longitude": 15.37917
    },
    {
      "zip": 4209,
      "city": "Žabnica",
      "latitude": 46.19861,
      "longitude": 14.33306
    },
    {
      "zip": 3310,
      "city": "Žalec",
      "latitude": 46.25151,
      "longitude": 15.16482
    },
    {
      "zip": 4228,
      "city": "Železniki",
      "latitude": 46.21999,
      "longitude": 14.14159
    },
    {
      "zip": 2287,
      "city": "Žetale",
      "latitude": 46.27356,
      "longitude": 15.82658
    },
    {
      "zip": 4226,
      "city": "Žiri",
      "latitude": 46.04222,
      "longitude": 14.10722
    },
    {
      "zip": 4274,
      "city": "Žirovnica",
      "latitude": 46.40472,
      "longitude": 14.14
    },
    {
      "zip": 8360,
      "city": "Žužemberk",
      "latitude": 45.83389,
      "longitude": 14.92917
    }
  ]

  constructor() { }

  getAll(): any[] {
    var cts = this.cities.map( x => {
      (delete x.latitude);
      (delete x.longitude);
      return x
    });
    return cts;
  }

  searchResults(search: string): City[] {
    return this.cities.filter( x => x.city.toLowerCase().includes(search.toLowerCase()) || x.zip.toString().toLowerCase().includes(search.toLowerCase()) );
  }

}
