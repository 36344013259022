import { Injectable } from '@angular/core';

@Injectable()
export class TranslationsService {

  public translation: any = {
    "general": {
      "loading": "Nalaganje...",
      "databaseUrl": '/api/models/renault/sl',
      "databaseVehiclesUrl": '/api/vehicles/renault/sl/',
      "databaseVehicleUrl": '/api/vehicle/renault/sl/',
      "locale": "sl",
    },
    "carPickerPage": {
      "title": "Preizkusite in zapeljite na testno vožnjo svoje vozilo Renault.",
      "title2": "Rezervirajte testno vožnjo.",
      "title3": "Izberite model, ki ga želite preizkusiti."
    },
    "carFilterPage": {
      "wantTestDrive": "Želim testno vožnjo z vozilom",
      "carCharacteristics": "Izberite značilnosti vozila",
      "filter": {
        "fuel": "vrsta goriva",
        "transmission": "vrsta menjalnika",
        "engine": "vrsta motorja",
        "drive": "vrsta pogona",
        "body" : "različica",
        "search": "Iskanje po območju",
        "searchPlaceholder": "Vpišite želeno lokacijo (npr. Ljubljana)",
        "searchDisclaimer": "Vozila bodo razvrščena po zračni oddaljenosti od lokacije, kjer želite preizkusiti vozilo.",
        "clearFilters": "Počistite vse filtre"
      },
      "vehiclesNumber": "Vozila po vaši izbiri",
      "carDetails": {
        "price": "cena novega vozila:",
        "fuel": "gorivo",
        "transmission": "menjalnik",
        "power": "moč motorja v kW (KM)",
        "doors": "število vrat",
        "color": "barva",
        "spec": "specifikacija opreme",
        "image": "Slika je simbolična."
      },
      "dealerMap": "Lokacija pooblaščenega prodajnega salona",
      "buttons": {
        "testDrive": "ŽELIM PREIZKUSITI TO VOZILO"
      }
    },
    "leadForm": {
      "lang": "si",
      "brand": "RENAULT",
      "formName": "LEADFORMA Testna Voznja",
      "title": "Za testno vožnjo ste izbrali:",
      "target": "Internet",
      "carDetails": {
        "price": "cena novega vozila:",
        "fuel": "gorivo",
        "transmission": "menjalnik",
        "power": "moč motorja v kW (KM)",
        "doors": "število vrat",
        "color": "barva",
        "spec": "specifikacija opreme"
      },
      "dealerText": "Testno vozilo boste preizkusili pri pooblaščenem prodajalcu vozil Renault:",
      "thankYou": "Hvala za rezervacijo testne vožnje.<br>V kratkem vas bo kontaktiral komercialni svetovalec iz izbranega pooblaščenega salona Renault.",
      "form": {
        "title": "Prosimo, izpolnite vaše podatke.",
        "title2": "Za termin testne vožnje vas bo kontaktiral komercialni svetovalec iz izbranega pooblaščenega salona Renault.",
        "gender": "Naziv",
        "date": "Datum",
        "time": "Čas",
        "name": "Ime",
        "lastName": "Priimek",
        "email": "E-pošta",
        "emailTest": "Potrdite e-pošto",
        "post": "Poštna številka",
        "phone": "Številka telefona",
        "terms": "S posredovanjem osebnih podatkov soglašam, da se moji osebni podatki obdelujejo v zbirki osebnih podatkov upravljavca Renault Nissan Slovenija, d.o.o., za namen obveščanja o novostih, ponudbah in ugodnostih, pošiljanja ponudb,  vabil na dogodke, anketiranja o proizvodih in storitvah ter statistične obdelave in segmentacije kupcev, ter dovolim kontakt v pisni, telefonski ali elektronski obliki.<br><br>Vaše osebne podatke zbiramo in obdelujemo skladno z določbami zakonodaje s področja varstva osebnih podatkov. Zbranih podatkov brez vašega izrecnega dovoljenja ne bomo posredovali tretjim osebam. Posameznik ima pravico do vpogleda v zbirko osebnih podatkov, pravico zahtevati dostop do svojih osebnih podatkov in spremembe le-teh, pravico do popravka, pozabe, omejitve obdelave, ugovora in prenosljivosti podatkov ter sme v vsakem času preklicati svojo privolitev za uporabo osebnih podatkov, prav tako pa ima pravico zahtevati spremembe ali izbris iz baze podatkov. To stori tako, da piše službi za stike s kupci na naslednji e-naslov: <a href='mailto:pisite.nam@renault.si'>pisite.nam@renault.si</a>.",       
        "noResults": "Vpišite veljavno poštno številko.",
        "error": {
          "gender": "Izberite svoj naziv.",
          "date": "Izbira datuma je obvezna.",
          "time": "Izbira ure je obvezna.",
          "name": "Vpišite svoje ime.",
          "lastName": "Vpišite svoj priimek.",
          "email": "Vpišite svoj e-naslov.",
          "emailTest": "Potrditi morate e-naslov.",
          "post": "Vpišite poštno številko.",
          "phone": "Zapišite številko telefona brez presledkov ali ločil.",
          "terms": "Za nadaljevanje se morate strinjati s pravnim obvestilom."
        },
        "help": {
          "time": "Izberite želeno uro",
        },
        "hint": {
          "time": "Izbrani termin je informativne narave. O razpoložljivem terminu vas bo obvestil izbrani pooblaščeni prodajalec.",
          "emailTest": "Prosimo, preverite, da ste pravilno vpisali naslov e-pošte, da vas bomo lahko kontaktirali in povabili na testno vožnjo.",
          "phone": "Vpišite številko telefona brez presledkov ali ločil."
        },
        "required": "Vsa polja so obvezna.",
        "submit": "REZERVIRAJTE TESTNO VOŽNJO",
        "back": "nazaj na izbor vozil"
      },
    },
  };

  constructor() { }

}