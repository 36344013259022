import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Car } from '../car';
import { CarListService } from '../car-list.service';
import { CarPickerService } from '../car-picker.service';

import { TranslationsService } from '../local/translations.service';

@Component({
  selector: 'app-car-picker',
  templateUrl: './car-picker.component.html',
  styleUrls: ['./car-picker.component.scss'],
  providers: [
    CarPickerService,
    CarListService,
    TranslationsService
  ]
})
export class CarPickerComponent implements OnInit {

  cars: Car[];
  models: string[];
  currentTab: string;

  constructor(
    private carPickerService: CarPickerService,
    private carListService: CarListService,
    private route: ActivatedRoute,
    public trns: TranslationsService
  ) { }

  updateCars() {
    this.cars = this.carPickerService.getFilter(this.models, this.currentTab);
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const bir = params['bir'] || '';
      this.currentTab = params['tab'] || 'osebna-vozila';
      this.carListService.getModels(bir).subscribe(
        (data: any) => {
          this.models = data;
          this.updateCars();
        },
        err => console.log(err)
      );
    });
  }
}
