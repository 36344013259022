import { Injectable } from '@angular/core';

import { Car } from './car';
import { Tab } from './tab';

@Injectable()
export class CarPickerService {

  cars: Car[] = [
    {
      id: 'twingo',
      name: 'TWINGO',
      modelCode: '2W3',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/twingo.jpg'
    },
    {
      id: 'twingo-gt',
      name: 'TWINGO GT',
      modelCode: 'TW3_GT',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/twingo-gt.jpg'
    },
    {
      id: 'clio',
      name: 'CLIO',
      modelCode: 'CL4',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/clio.jpg'
    },
    {
      id: 'novi-clio',
      name: 'Novi CLIO',
      modelCode: 'CL5',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/clio.jpg'
    },
   // {
   //   id: 'clio-grandtour',
   //     name: 'CLIO Grandtour',
   //     modelCode: 'CK4',
   //   tab: 'osebna-vozila',
   //   image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/clio-grandtour.jpg'
   // },
    {
      id: 'clio-rs',
      name: 'CLIO R.S.',
      modelCode: 'CL4_RS',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/clio-rs.jpg'
    },
    {
      id: 'novi-captur',
      name: 'Novi CAPTUR',
      modelCode: 'CP1',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/novi-captur.jpg'
    },
    // {
    //   id: 'captur',
    //   name: 'CAPTUR',
    //   modelCode: '87J',
    //   tab: 'osebna-vozila',
    //   image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/captur.jpg'
    // },
    {
      id: 'captur-initiale-paris',
      name: 'CAPTUR INITIALE PARIS',
      modelCode: '87J_IP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/captur.jpg'
    },
    {
      id: 'kangoo',
      name: 'KANGOO',
      modelCode: 'KP2',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/kangoo.jpg'
    },
    {
      id: 'kangoo-express',
      name: 'KANGOO Express',
      modelCode: 'KU2',
      tab: 'lahka-gospodarska-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/kangoo-express.jpg'
    },
    {
      id: 'megane',
      name: 'MEGANE',
      modelCode: 'MB4',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane.jpg'
    },
    {
      id: 'megane-gt',
      name: 'MEGANE GT',
      modelCode: 'MB4_GT',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-gt.jpg'
    },
    {
      id: 'megane-grandtour',
      name: 'MEGANE Grandtour',
      modelCode: 'MK4',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane-grandtour.jpg'
    },
    {
      id: 'megane-grandtour-gt',
      name: 'MEGANE Grandtour GT',
      modelCode: 'MK4_GT',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-gt.jpg'
    },
    {
      id: 'megane-grandcoupe',
      name: 'MEGANE GrandCoupé',
      modelCode: 'FF1',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane-grandcoupe.jpg'
    },
    {
      id: 'novi-megane-rs',
      name: 'Novi MEGANE R.S.',
      modelCode: 'BFB_RS',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-rs.jpg'
    },

    {
      id: 'kadjar',
      name: 'KADJAR',
      modelCode: 'FEH',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/kadjar.jpg'
    },
    {
      id: 'scenic',
      name: 'SCENIC',
      modelCode: 'SC4',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/scenic.jpg'
    },
    {
      id: 'grand-scenic',
      name: 'Grand SCENIC',
      modelCode: 'SL4',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/grand-scenic.jpg'
    },
    {
      id: 'novi-talisman',
      name: 'Novi TALISMAN',
      modelCode: 'FDL',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman.jpg'
    },
    {
      id: 'talisman-initiale-paris',
      name: 'TALISMAN INITIALE PARIS',
      modelCode: 'FDL_IP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman.jpg'
    },
    {
      id: 'novi-talisman-grandtour',
      name: 'Novi TALISMAN Grandtour',
      modelCode: 'FDK',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman-grandtour.jpg'
    },
    {
      id: 'talisman-grandtour-initiale-paris',
      name: 'TALISMAN Grandtour INITIALE PARIS',
      modelCode: 'FDK_IP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman-grandtour.jpg'
    },
    {
      id: 'koleos',
      name: 'Novi KOLEOS',
      modelCode: 'ZGH',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/koleos.jpg'
    },
    {
      id: 'koleos-initiale-paris',
      name: 'KOLEOS INITIALE PARIS',
      modelCode: 'ZGH_IP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/koleos.jpg'
    },
    {
      id: 'espace',
      name: 'ESPACE',
      modelCode: 'SP5',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/espace.jpg'
    },
    {
      id: 'espace-initiale-paris',
      name: 'ESPACE INITIALE PARIS',
      modelCode: 'SP5_IP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/espace.jpg'
    },
    {
      id: 'twizy',
      name: 'TWIZY',
      modelCode: 'TWZ',
      tab: 'elektricna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/twizy.jpg'
    },
    {
      id: 'kangoo-ze',
      name: 'KANGOO Z.E.',
      modelCode: 'KQ2',
      tab: 'elektricna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/zoe.jpg'
    },
    {
      id: 'novi-zoe',
      name: 'Novi ZOE',
      modelCode: 'ZOE',
      tab: 'elektricna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/elektricna-vozila/novi-zoe.jpg'
    },
    {
      id: 'novi-twingo-elec',
      name: 'Novi TWINGO Electric',
      modelCode: '2WE',
      tab: 'elektricna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/elektricna-vozila/novi-zoe.jpg'
    },
    {
      id: 'novi-trafic',
      name: 'Novi TRAFIC',
      modelCode: 'TRU',
      tab: 'lahka-gospodarska-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/trafic-furgon.jpg'
    },
    {
      id: 'novi-trafic-passenger',
      name: 'Novi TRAFIC Passenger',
      modelCode: 'TRP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/trafic-passenger.jpg'
    },
    {
      id: 'novi-trafic-spaceclass',
      name: 'Novi TRAFIC SpaceClass',
      modelCode: 'TRP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/novi-trafic-spaceclass.jpg'
    },
    {
      id: 'novi-master-passenger',
      name: 'Novi MASTER Passenger',
      modelCode: 'R3P',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/trafic-passenger.jpg'
    },
    // {
    //   id: 'master',
    //   name: 'MASTER',
    //   modelCode: 'MAU',
    //   tab: 'lahka-gospodarska-vozila',
    //   image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/master-furgon.jpg'
    // },
    {
      id: 'novi-master',
      name: 'Novi MASTER',
      modelCode: 'R3U',
      tab: 'lahka-gospodarska-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/master-furgon.jpg'
    },
    {
      id: 'master-passenger',
      name: 'MASTER Passenger',
      modelCode: 'MAP',
      tab: 'osebna-vozila',
      image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/master-passenger.jpg'
    }
  ]

  tabs: Tab[] = [
    {
      id: 'osebna-vozila',
      name: 'OSEBNA VOZILA',
      sort: 0
    },
    {
      id: 'elektricna-vozila',
      name: 'ELEKTRIČNA VOZILA',
      sort: 1
    },
    {
      id: 'lahka-gospodarska-vozila',
      name: 'LAHKA GOSPODARSKA VOZILA',
      sort: 2
    }
  ]

  constructor() { }

  getAll(): Car[] {
    return this.cars;
  }

  getTabs() : Tab[] {
    return this.tabs;
  }

  getTabGrouped(models: string[]): any {
    var group = [];
    var tabs = this.tabs;
    this.cars.filter( item => models.some( f => f == item.modelCode)).forEach(function(el) {
      var tab = tabs.find( x => x.id === el.tab );
      if(typeof group[tab.sort] === 'undefined'){
        group[tab.sort] = {
          'name': tab.name,
          'sort': tab.sort,
          'cars': []
        };
      }
      group[tab.sort].cars.push(el);
    })
    console.log(group)
    return group;
  }

  getAllByTab(tab: String): Car[] {
    return this.cars.filter( item => item.tab == tab);
  }

  getFilter(models: string[], tab: String = ''): Car[] {
    if(tab == ''){
      return this.cars.filter( item => models.some( f => f == item.modelCode));
    }
    return this.cars.filter( item => models.some( f => f == item.modelCode) && item.tab == tab);
  }

  getModel(name: string): Car {
    return this.cars.find( car => car.id == name);
  }

  getId(model: string): String {
    var car = this.cars.find( car => car.modelCode == model);
    return car.id;
  }

}
