import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { trigger, state, animate, transition, style } from '@angular/animations';

import { Car } from '../car';
import { City } from '../city';
import { CarListService } from '../car-list.service';
import { CarPickerService } from '../car-picker.service';
import { FilterListService } from '../filter-list.service';
import { DealerListService } from '../dealer-list.service';
import { CitySearchService } from '../city-search.service';

import { TranslationsService } from '../local/translations.service';


@Component({
  selector: 'app-car-filter-ev',
  templateUrl: './car-filter-ev.component.html',
  styleUrls: ['./car-filter-ev.component.scss'],
  animations: [
    trigger('filterPreparing', [
      state('loading', style({
        opacity: 0.2,
      })),
      state('loaded', style({
        opacity: 1,
      })),
      transition('loading => loaded', animate('100ms ease-in')),
      transition('loaded => loading', animate('100ms ease-out'))
    ]),
    trigger('filterSelect', [
      state('*', style({
        transform: 'scale(1)',
      })),
      state('void', style({
        transform: 'scale(0.1)'
      })),
      transition(':enter', animate('100ms ease-in')),
      transition(':leave', animate('100ms ease-out'))
    ]),
    trigger('filterLoaded', [
      state('*', style({
        transform: 'scale(1)',
      })),
      state('void', style({
        transform: 'scale(0.1)'
      })),
      transition(':enter', animate('100ms ease-in')),
      transition(':leave', animate('100ms ease-out'))
    ]),
    trigger('routeChange', [
      transition('void => *', [
        style({
          transform: 'translateX(100px)',
          opacity: 0.2
        }),
        animate('200ms ease-in', style({
          transform: 'translateX(0)',
          opacity: 1
        }))
      ])
    ]),
    trigger('carList', [
      transition('* => void', [
        style({
          transform: 'scale(1)',
          opacity: 1
        }),
        animate('200ms ease-in', style({
          transform: 'scale(0)',
          opacity: 0.2
        }))
      ]),
      transition('void => *', [
        style({
          transform: 'scale(0)',
          opacity: 0.2
        }),
        animate('200ms ease-out', style({
          transform: 'scale(1)',
          opacity: 1
        }))
      ]),
    ])
  ],
  providers: [
    CarPickerService,
    CarListService,
    FilterListService,
    DealerListService,
    CitySearchService,
    TranslationsService
  ],
  host: {
    '[@routeChange]': ''
  }
})
export class CarFilterEvComponent implements OnInit {

  pageId: string;
  state: string = 'loading';
  loading: boolean = false;
  list: any[] = [];
  tempList: any[] = [];
  models: Car[];
  current: Car;
  tabGrouped: any;
  filter: any = {
    fuel: [],
    engine: [],
    transmission: [],
    drive: []
  };
  filters: number;
  search: string;
  searchData: City;
  searchResults: City[];
  dropdownSelected: any = {};
  bir: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private carPickerService: CarPickerService,
    private carListService: CarListService,
    private filterListService: FilterListService,
    private dealerListService: DealerListService,
    private citySearchService: CitySearchService,
    public trns: TranslationsService
  ) { }

  ngOnInit() {
    this.filter = {
      fuel: [],
      engine: [],
      transmission: [],
      drive: []
    };
    this.getData();
  }

  getData() {
    this.route.params.subscribe( params => {
      this.loading = true;
      this.list = [];
      this.bir = params['bir'] || '';
      this.pageId = params['model'];
      this.filter = {
        fuel: [],
        engine: [],
        transmission: [],
        drive: []
      };
      Observable.forkJoin(
        this.filterListService.getAll(this.pageId, this.bir),
        this.carListService.getModels(this.bir)
      ).subscribe(
        (data) => {
          if(data[0].length == 0){
            this.router.navigate(['../'], {relativeTo: this.route, queryParamsHandling: "merge"});
          }
          this.list = data[0];
          this.tempList = this.list;
          this.updateFilter(true);
          if(this.route.snapshot.queryParamMap.get("engine")) this.updateFilter();
          this.current = this.carPickerService.getModel(this.pageId);
          let models: any = data[1];
          this.models = this.carPickerService.getFilter(models);
          this.sortList();
          this.loading = false;
          this.state = 'loaded';
          this.tabGrouped = this.carPickerService.getTabGrouped(models);
        }
      );
    });
  }

  getAltImage(model) {
    var id = this.carPickerService.getId(model);
    return '/assets/cars/' + id + '.jpg';
  }

  imageError(event, koda) {
    event.target.src = this.getAltImage(koda);
  }

  popup(index) {
    this.list[index].popup = true;
  }

  currentCarName() {
    if(this.current){
      return this.current.name;
    }else{
      return "";
    }
  }

  closePopup(index) {
    this.list[index].popup = false;
  }

  changeModel(event) {
    console.log(this.dropdownSelected.id);
    if(this.dropdownSelected.id != this.pageId){
      this.filter = {
        fuel: [],
        engine: [],
        transmission: [],
        drive: []
      };
      this.router.navigate(['../../', this.dropdownSelected.tab, this.dropdownSelected.id], {
        queryParams: {
          engine: null
        },
        relativeTo: this.route,
        queryParamsHandling: "merge"
      });
    }
  }

  searchChange() {
    if(this.search.length > 2) {
  	  this.searchResults = this.citySearchService.searchResults(this.search);
  	}else{
  	  this.searchResults = [];
  	}
  }

  selectResult(index){
    this.search = this.searchResults[index].city;
    this.searchData = this.searchResults[index];
    this.searchResults = [];
    this.sortList();
  }

  clearSearch(){
    this.search = "";
    this.searchData = null;
    this.searchResults = [];
    this.list = this.tempList;
    this.updateFilter();
    this.sortList();
  }

  sortList() {
    if(this.searchData) {
      for (let model of this.list) {
        //calculation for geolocation distance taken from here: http://www.movable-type.co.uk/scripts/latlong.html
        var R = 6371e3; // metres
        var φ1 = this.searchData.latitude * (Math.PI / 180);
        var φ2 = model.dealer.geolocation.latitude * (Math.PI / 180);
        var Δφ = (model.dealer.geolocation.latitude-this.searchData.latitude) * (Math.PI / 180);
        var Δλ = (model.dealer.geolocation.longitude-this.searchData.longitude) * (Math.PI / 180);
        
        var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ/2) * Math.sin(Δλ/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        
        var d = R * c;
        model.distance = d;
      }
      this.list.sort( (x, y) => {
        return x.distance - y.distance;
      })
    }else{
      this.list.sort( (x, y) => {
        if(x.dealer.sortName < y.dealer.sortName) return -1;
        if(x.dealer.sortName > y.dealer.sortName) return 1;
        return 0;
      })
    }
  }

  clearFilters() {
    this.search = null;
    this.searchData = null;
    this.filter.fuel.map( x => x.checked = false);
    this.filter.engine.map( x => x.checked = false);
    this.filter.transmission.map( x => x.checked = false);
    this.filter.drive.map( x => x.checked = false);
    this.updateFilter();
    this.sortList();
  }

  removeFilter(type, index) {
    if(type == "fuel") {
      this.filter.fuel[index].checked = false;
    }
    if(type == "transmission") {
      this.filter.transmission[index].checked = false;
    }
    if(type == "engine") {
      this.filter.engine[index].checked = false;
    }
    if(type == "drive") {
      this.filter.drive[index].checked = false;
    }
    this.updateFilter();
    this.sortList();
  }

  filterChecked() {
    if(this.filter.fuel.filter( x => x.checked == true).length > 0 ||
      this.filter.engine.filter( x => x.checked == true).length > 0 ||
      this.filter.transmission.filter( x=> x.checked == true).length > 0 ||
      this.filter.drive.filter( x=> x.checked == true).length > 0)
    {
      return true;
    }
    return false;
  }

  getTransmission(car) {
    return car.menjalnik;
  }

  getDors(car) {
    return car
      .technicalDetails
      .equipmentCategoryList
      .find(x => x.code == 'DEC393')
      .options.
      find(x => x.code == 'PT1262')
      .value;
  }

  updateFilter(first = false) {
    this.list = this.tempList;
    let engineFilter = this.tempList;
    let transmissionFilter = this.tempList;
    let fuelFilter = this.tempList;
    let driveFilter = this.tempList;

    if(this.filter){

      for(let f of this.filter.fuel) {
        f.count = 0;
      }

      for(let e of this.filter.engine) {
          e.count = 0;
      }

      for(let t of this.filter.transmission) {
          t.count = 0;
      }

      for(let t of this.filter.drive) {
        t.count = 0;
      }

      let fuelChecked = this.filter.fuel.filter(
        x => x.checked == true
      );
      if(fuelChecked.length > 0) {
        let checked = fuelChecked.map( x => x.name );
        this.list = this.list.filter(
          x => checked.some( f => f == x.gorivo)
        );
        engineFilter = engineFilter.filter(
          x => checked.some( f => f == x.gorivo)
        );
        transmissionFilter = transmissionFilter.filter(
          x => checked.some( f => f == x.gorivo)
        );
        driveFilter = driveFilter.filter(
          x => checked.some( f => f == x.gorivo)
        );
      }

      let engineChecked = this.filter.engine.filter(
        x => x.checked == true
      );
      if(engineChecked.length > 0) {
        let checked = engineChecked.map( x => x.name );
        this.list = this.list.filter(
          x => checked.some( f => f == x.motor)
        );
        fuelFilter = fuelFilter.filter(
          x => checked.some( f => f == x.motor)
        );
        transmissionFilter = transmissionFilter.filter(
          x => checked.some( f => f == x.motor)
        );
        driveFilter = driveFilter.filter(
          x => checked.some( f => f == x.motor)
        );
      }

      let transmissionChecked = this.filter.transmission.filter(
        x => x.checked == true
      );
      if(transmissionChecked.length > 0) {
        let checked = transmissionChecked.map( x => x.name );
        this.list = this.list.filter(
          x => checked.some( f => f == x.menjalnik )
        );
        engineFilter = engineFilter.filter(
          x => checked.some( f => f == x.menjalnik)
        );
        fuelFilter = fuelFilter.filter(
          x => checked.some( f => f == x.menjalnik)
        );
        driveFilter = driveFilter.filter(
          x => checked.some( f => f == x.menjalnik)
        );
      }

      let driveChecked = this.filter.drive.filter(
        x => x.checked == true
      );
      if(driveChecked.length > 0) {
        let checked = driveChecked.map( x => x.name );
        this.list = this.list.filter(
          x => checked.some( f => f == x.pogon )
        );
        engineFilter = engineFilter.filter(
          x => checked.some( f => f == x.pogon)
        );
        fuelFilter = fuelFilter.filter(
          x => checked.some( f => f == x.pogon)
        );
        transmissionFilter = transmissionFilter.filter(
          x => checked.some( f => f == x.pogon)
        );
      }
    } else {
      this.filter = {
        fuel: [],
        engine: [],
        transmission: [],
        drive: []
      };
    }
    
    var engineParamArray = this.route.snapshot.queryParamMap.get("engine") ? this.route.snapshot.queryParamMap.get("engine").split(",") : [];

    for (let engineModel of engineFilter) {
      let engine = engineModel.motor;
      if(engine != '') {
        let eIdx = this.filter.engine.findIndex(
          x => x.name == engine
        );
        if(eIdx !== -1){
          this.filter.engine[eIdx].count += 1;
        }else{
          var checked = engineParamArray.indexOf(engine) > -1; // preselect engine if query param "engine" matches
          this.filter.engine.push({name: engine, count: 1, checked: checked});
        }
      }
    }


    for (let transmissionModel of transmissionFilter) {
      let transmission = transmissionModel.menjalnik;
      if(transmission != ''){
        let tIdx = this.filter.transmission.findIndex(
          x => x.name == transmission
        );
        if(tIdx !== -1){
          this.filter.transmission[tIdx].count += 1;
        }else{
          this.filter.transmission.push({name: transmission, count: 1});
        }
      }
    }

    for (let fuelModel of fuelFilter) {
      let fuel = fuelModel.gorivo;
      if(fuel != ''){
        let fIdx = this.filter.fuel.findIndex(
          x => x.name == fuel
        );
        if(fIdx !== -1){
          this.filter.fuel[fIdx].count += 1;
        }else{
          this.filter.fuel.push({name: fuel, count: 1});
        }
      }
    }

    driveFilter = driveFilter.filter(x => x.pogon != "");
    for (let driveModel of driveFilter) {
      let drive = driveModel.pogon;
      if(drive != '' && drive != undefined){
        let fIdx = this.filter.drive.findIndex(
          x => x.name == drive
        );
        if(fIdx !== -1){
          this.filter.drive[fIdx].count += 1;
        }else{
          this.filter.drive.push({name: drive, count: 1});
        }
      }
    }

    if(first) {
      this.filter.engine.sort((x, y) => {
        return y.count - x.count;
      });
      this.filter.fuel.sort((x, y) => {
        return y.count - x.count;
      });
      this.filter.transmission.sort((x, y) => {
        return y.count - x.count;
      });
      this.filter.drive.sort((x, y) => {
        return y.count - x.count;
      });
    }
  }
}