import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { trigger, state, animate, transition, style } from '@angular/animations';

import { CarListService } from '../car-list.service';
import { DealerListService } from '../dealer-list.service';
import { CarPickerService } from '../car-picker.service';

import { TranslationsService } from '../local/translations.service';

import { CitySearchService } from '../city-search.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.scss'],
  animations: [
    trigger('routeChange', [
      transition('void => *', [
        style({
          transform: 'translateX(100px)',
          opacity: 0.2
        }),
        animate('200ms ease-in', style({
          transform: 'translateX(0)',
          opacity: 1
        }))
      ])
    ])
  ],
  providers: [
    CarListService,
    DealerListService,
    TranslationsService,
    CarPickerService,
    CitySearchService
  ],
  host: {
    '[@routeChange]': ''
  }
})
export class LeadFormComponent implements OnInit {

  vin: string;
  tab: string;
  comply: Boolean = true;
  car: any = {
    config: "",
    dealer: "",
  };
  time: string;
  submitted: boolean = false;
  wasSubmitButtonClicked: boolean = false;
  userForm: any = {};
  isUserFormValid: boolean = false;
  today: Date = new Date();
  todayIso: any = {year: this.today.getFullYear(), month: this.today.getMonth()+1, day: this.today.getDate()+5}
  cities: any[] = [];
  campaign: any = this.trns.translation.leadForm.formName;
  target: any = '';
  window: any = window;
  ePosta: boolean = false;
  sms: boolean = false;
  telefon: boolean = false;
  obicajnaPosta: boolean = false;
  personalization = null;
  newsletter = null;
  purchase = false;
  $: any = $;


  timeOptions: any = [
    {
        'value': '8:00',
        'name': '8:00'
    },
    {
        'value': '9:00',
        'name': '9:00'
    },
    {
        'value': '10:00',
        'name': '10:00'
    },
    {
        'value': '11:00',
        'name': '11:00'
    },
    {
        'value': '12:00',
        'name': '12:00'
    },
    {
        'value': '13:00',
        'name': '13:00'
    },
    {
        'value': '14:00',
        'name': '14:00'
    },
    {
        'value': '15:00',
        'name': '15:00'
    },
    {
        'value': '16:00',
        'name': '16:00'
    },
    {
        'value': '17:00',
        'name': '17:00'
    }
];

  lead = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    post: '',
    emailTest: '',
    time: '',
    date: '',
    title: '',
    street: '',
    streetNumber: ''
  }

  datepickerOptions: any = {
    showTodayBtn: false,
    disableWeekends: true,
    disableSince: {year: this.today.getFullYear(), month: this.today.getMonth()+1, day: this.today.getDate()+90},
    disableUntil: {year: this.today.getFullYear(), month: this.today.getMonth()+1, day: this.today.getDate()},
    inline: true,
    locale: 'sl',
    dateFormat: 'yyyy-mm-dd',
  }

  terms = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private carListService: CarListService,
    private dealerListService: DealerListService,
    private carPickerService: CarPickerService,
    private citySearchService: CitySearchService,
    private http: HttpClient,
    public trns: TranslationsService,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    
    if(this.isCorrectHostname(window.location.hostname)) {
      console.log("inside");
      this.pushGTMFormOpen();
    }

    
    this.route.queryParams.subscribe( params => {
      this.campaign = params['CAMPAIGN'] || this.campaign;
    })
    this.route.params.subscribe( params => {
      this.vin = params['vin'];
      

      this.cities = this.citySearchService.getAll();
      this.carListService.getByVin(this.vin).subscribe (
        data => {
          if(!data){
            this.router.navigate(['../'], {relativeTo: this.route});
          }
          this.car = data
          this.car.dealer = this.dealerListService.find(this.car.bir)
        }
      );
    });
  }

  onSubmit(valid) {
    this.wasSubmitButtonClicked = true;
    console.log(this.lead.date);
    if(valid.valid && this.isUserFormValid) {
      var dd: any =  this.lead.date;
      var pp: any =  this.lead.post;

      let test_drive = {
        car: this.car,
        user: this.userForm.user,
        service: {
          service: null,
          notes: ''
        },
        consent: this.comply,
        dealer: {
          dealerId: this.car.bir,
          suggestedName: this.car.dealer.suggestedName
        },
        appointment: {
          date: dd.formatted,
          time: this.lead.time
        },
        formName: this.campaign,
        target: this.trns.translation.leadForm.target,
        gdpr: this.userForm.gdpr,
        mmv_desir1: this.car.komModel.substring(0, 25) +  '(' + this.vin + ')',
        mmv_desir2: this.vin
      }

      this.submitted = true;
      parent.postMessage('Submit', '*')
      this.http.post('/api/v2/vega/testdrive/renault/si', test_drive)
      .subscribe(
           (data) => {
             console.log(data)
       });

      if(this.isCorrectHostname(window.location.hostname)) {
        console.log("SUBMIT IF OCCURED");
        this.pushGTMFormSubmit();
      }

       $('html, body').animate({
        scrollTop: 30
      }, 600)
    } else {
      setTimeout(function() {
        if ($('.error, .isInvalid').length) {
          $('html, body').animate({
            scrollTop: $('.error, .isInvalid').first().offset().top - 100
          }, 600)
        }
      }, 100)
    }
  }

  onBack () {
    this._location.back();
  }

  getAltImage(model) {
    var id = this.carPickerService.getId(model);
    return '/assets/cars/' + id + '.jpg';
  }

  checkValid () {
    if (this.ePosta === false && this.sms === false && this.telefon === false && this.obicajnaPosta === false && !(this.personalization === false && this.newsletter === false && this.purchase === false)) {
      return false
    } else {
      return true
    }
  }

  checkGdpr () {
    if (this.comply === false) {
      return true;
    } else {
      if (
        this.personalization == null ||
        this.newsletter == null ||
        this.purchase == null) {
        return false;
      } else {
        if (this.obicajnaPosta === true && (this.lead.street === '' || this.lead.streetNumber === '')) {
          return false
        }
        if ((this.sms === true || this.telefon === true) && (this.lead.phone === '')) {
          return false
        }
        if (this.checkValid()) {
          return true
        }
      }
      return false;
    }
  }
  
  userInfoChanged(event) {
    if(!event.detail) return;
    
    var userData = event.detail[0];
    this.isUserFormValid = userData.isFormValid;
    if (userData.isFormValid) {
      this.userForm = userData.form;
    }
  }

  imageError(event, koda) {
    event.target.src = this.getAltImage(koda);
    this.car.image = 'http://testna-voznja.renault.si' + this.getAltImage(koda);
  }

  autocompleListFormatter(data: any) {
    return data.zip + " " + data.city;
  }

  isCorrectHostname (hostname) {
    // return true; // for testing uncomment
    return hostname === 'testna-voznja.renault.si';
  }

  pushGTMFormOpen() {
    (<any>window).dataLayer.push({
      'brand': 'renault',
      'googleAccount': 'UA-19767187-1',
      'pageName': 'Testna Voznja | Renault Slovenija',
      'businessType': 'new-car',
      'pageType': 'form',
      'languageCode': 'sl',
      'countryCode': 'SI',
      'formName': 'book-a-test-drive',
      'formType': 'test_drive_request',
      'formCategory': 'lead_newcar',
      'formStepName': 'start',
      'formStepNumber': 'step-0',
      'event': 'formOpen'
    });
  }

  pushGTMFormSubmit() {
    (<any>window).dataLayer.push({
      'brand': 'renault',
      'googleAccount': 'UA-19767187-1',
      'pageName': 'Testna Voznja | finish | Renault Slovenija',
      'businessType': 'new-car',
      'pageType': 'form',
      'languageCode': 'sl',
      'countryCode': 'SI',
      'event': 'virtualPageview'
    });

    (<any>window).dataLayer.push({
      'brand': 'renault',
      'googleAccount': 'UA-19767187-1',
      'pageName': 'Testna Voznja | finish | Renault Slovenija',
      'businessType': 'new-car',
      'pageType': 'form',
      'languageCode': 'sl',
      'countryCode': 'SI',
      'formName': 'book-a-test-drive',
      'formType': 'test_drive_request',
      'formCategory': 'lead_newcar',
      'formStepName': 'confirmation',
      'formStepNumber': 'complete',
      'event': 'formValidate',
      'testDriveModelName': this.car.komModel
    });
  }
}
